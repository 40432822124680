import { Helmet } from "react-helmet";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import AmenitiesSection from "./AmenitiesSection";
import AppDownloadSection from "./AppDownloadSection";
import CustomerReviewsSection from "./CustomerReviewsSection";
import FleetSection from "./FleetSection";
import HomeBannerSection from "./HomeBannerSection";
import HowWeMakeADifferenceSection from "./HowWeMakeADifferenceSection";
import InvestmentSection from "./InvestmentSection";
import React from "react";

export default function Home1Page() {
  return (
    <>
      <Helmet>
        <title>Cab Booking - Hassle-Free Rides with S Mobility</title>
        <meta
          name="description"
          content="Book your next ride with S Mobility and enjoy zero cancellation, clean cabs, and polite drivers. Experience the difference with regular car maintenance and one-touch customer support."
        />
      </Helmet>
      <div className="w-full bg-white-a700">
        <Header />
        <div>
          {/* home banner section */}
          <HomeBannerSection />

          {/* how we make a difference section */}
          <HowWeMakeADifferenceSection />

          {/* amenities section */}
          <AmenitiesSection />

          {/* fleet section */}
          <FleetSection />

          {/* investment section */}
          <InvestmentSection />

          {/* customer reviews section */}
          <CustomerReviewsSection />

          {/* app download section */}
          <AppDownloadSection />
        </div>
        <Footer className="mt-[102px]" />
      </div>
    </>
  );
}
