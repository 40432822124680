import { Text, Heading, Img } from "./..";
import React from "react";
import { Link } from "react-router-dom";

export default function Footer({ ...props }) {
  return (
    <footer {...props} className={`${props.className} flex`}>
      <div className="relative h-[636px] w-full bg-[url(/public/images/img_group_311.png)] bg-cover bg-no-repeat">
        {/* Footer Content */}
        <div className="absolute left-0 right-0 top-[10%] mx-auto flex w-[58%] items-start justify-between gap-5 md:relative md:flex-col md:items-center md:w-[90%] sm:gap-[20px] sm:px-5 pb-[120px]">
          {/* Company Info */}
          <div className="flex w-[46%] flex-col gap-10 self-center md:w-full md:items-center">
            <Img
              src="images/img_footer_logo.svg"
              alt="Footerlogo"
              className="h-[80px] w-[186px] object-contain"
            />
            <Text
              size="texts"
              as="p"
              className="font-inter text-[16px] font-light leading-6 text-white-a700 lg:text-[13px] text-left md:text-center"
            >
              S Mobility is your trusted partner for hassle-free rides. Offering
              exceptional service with a focus on customer satisfaction, safety,
              and convenience. From daily commutes to long trips, we’ve got you
              covered.
            </Text>
            {/* Address Section */}
            <div className="flex flex-col items-start gap-10 md:items-center">
              <Heading
                size="headingxs"
                as="h4"
                className="font-inter text-[24px] font-semibold text-white-a700 lg:text-[20px] text-left"
              >
                Address
              </Heading>
              <Text
                as="p"
                className="font-inter text-[20px] font-normal text-white-a700 lg:text-[17px] text-left"
              >
                <>
                  SAURABHCHANDRA AUTOMOTIVE PRIVATE LIMITED
                  <br />
                  D-1b/538 Ground Floor, Sangam Vihar
                  <br />
                  New Delhi - 110080
                  <br />
                  India (IN)
                </>
              </Text>
            </div>
          </div>

          {/* Information Links */}
          <div className="flex w-[34%] flex-col items-start gap-[54px] md:w-full md:items-center sm:gap-[27px]">
            <Heading
              size="headingxs"
              as="h4"
              className="font-inter text-[24px] font-semibold text-white-a700 lg:text-[20px] text-center"
            >
              INFORMATION
            </Heading>
            <ul className="flex flex-col items-start gap-3 self-stretch md:items-center">
              <li>
                <Link to="/terms" className="lg:text-[17px]">
                  <Text
                    as="p"
                    className="font-inter text-[20px] font-normal text-white-a700 text-center"
                  >
                    Terms & Conditions
                  </Text>
                </Link>
              </li>
              <li>
                <Link to="/privacy" className="lg:text-[17px]">
                  <Text
                    as="p"
                    className="font-inter text-[20px] font-normal text-white-a700 text-center"
                  >
                    Privacy Policy
                  </Text>
                </Link>
              </li>
              <li>
                <Link to="/refund" className="lg:text-[17px]">
                  <Text
                    as="p"
                    className="font-inter text-[20px] font-normal text-white-a700 text-center"
                  >
                    Refund & Cancellation
                  </Text>
                </Link>
              </li>
              <li>
                <Link to="/delete-account" className="lg:text-[17px]">
                  <Text
                    as="p"
                    className="font-inter text-[20px] font-normal text-white-a700 text-center"
                  >
                    Account Deletion
                  </Text>
                </Link>
              </li>
            </ul>
          </div>
        </div>

        {/* Bottom Bar */}
        <div className="absolute bottom-0 left-0 right-0 mx-auto h-[82px] flex items-center justify-center lg:h-auto md:h-auto sm:flex-col sm:gap-2 sm:p-4">
          <Img
            src="images/img_rectangle_186.png"
            alt="Image"
            className="h-[82px] w-full object-cover sm:h-auto"
          />
          <Text
            size="texts"
            as="p"
            className="absolute text-center font-inter text-[16px] font-normal text-white-a700 lg:text-[13px] sm:relative sm:mt-2"
          >
            Copyright S Mobility @ 2024
          </Text>
        </div>
      </div>
    </footer>
  );
}
