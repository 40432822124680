import { Img, Heading } from "../../components";
import React from "react";

export default function AppDownloadSection() {
  return (
    <>
      {/* app download section */}
      <div id="app-download-section" className="mt-[114px] flex justify-center">
        <div className="container-xs flex justify-center lg:px-5 md:px-5">
          <div className="w-full rounded-[68px] bg-black-900_03">
            <div className="h-[630px] bg-[url(/public/images/img_group_104.png)] bg-cover bg-no-repeat py-[50px] lg:h-auto md:h-auto md:py-5 sm:py-4">
              <div className="mt-[5px] flex items-start md:flex-col p-10 ">
                <div className="mt-[5px] flex-1 px-14 md:self-stretch md:px-5 sm:px-4">
                  <div className="flex flex-col gap-[110px] lg:gap-[110px] md:gap-[82px] sm:gap-[55px]">
                    <div className="flex flex-col items-start gap-2">
                      <Heading
                        size="textxl"
                        as="h2"
                        className="text-[21px] font-medium uppercase tracking-[7.77px] text-white-a700 lg:text-[17px]"
                      >
                        Get Our App
                      </Heading>
                      <Heading
                        size="headingxl"
                        as="h3"
                        className="w-[94%] text-[59px] font-semibold leading-[88.26%] text-white-a700 lg:w-full lg:text-[50px] md:w-full md:text-[39px] sm:text-[33px]"
                      >
                        <span className="text-white-a700">Download&nbsp;</span>
                        <span className="text-blue-400">
                          <>
                            S Mobility
                            <br />
                            App For Free
                          </>
                        </span>
                      </Heading>
                    </div>
                    <div className="flex justify-between gap-5 sm:flex-col">
                      <a href="#">
                        <Img
                          src="images/img_group_413.png"
                          alt="Image"
                          className="h-[88px] w-[272px] rounded-[20px] object-contain"
                        />
                      </a>
                      <a href="#">
                        <Img
                          src="images/appstore_button.png"
                          alt="Image"
                          className="mr-[50px] h-[88px] w-[272px] rounded-[20px] object-contain sm:mr-0"
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <Img
                  src="images/img_frame_420.png"
                  alt="Image"
                  className="h-[498px] w-[44%] self-center object-contain md:w-full"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}