import { Text, Img } from "./..";
import React from "react";

export default function UserProfile({
  userImage = "images/img_user.svg",
  userInfoText = "Infotainment",
  ...props
}) {
  return (
    <div
      {...props}
      className={`${props.className} flex flex-col items-center justify-center text-center w-full gap-3`}
    >
      {/* Icon Wrapper */}
      <div className="flex h-[80px] w-[80px] items-center justify-center rounded-full bg-gray-100">
        <Img
          src={userImage}
          alt={userInfoText}
          className="h-[40px] w-[40px] object-contain"
        />
      </div>

      {/* Description */}
      <Text
        size="text2xl"
        as="p"
        className="text-[18px] font-medium leading-[1.5] !text-black-900"
      >
        {userInfoText}
      </Text>
    </div>
  );
}
