import React from "react";
import PropTypes from "prop-types";

const shapes = {
  round: "rounded-[26px]",
};
const variants = {
  outline: {
    black_900_02: "border-black-900_02 border border-solid text-black-900_03",
    white_A700: "border-white-a700 border-2 border-solid text-white-a700",
    black_900_03: "border-black-900_03 border border-solid text-black-900_03",
  },
  fill: {
    white_A700: "bg-white-a700 text-black-900_03",
    gray_200: "bg-gray-200 text-black-900_03",
    black_900_03: "bg-black-900_03 text-white-a700",
    blue_gray_100: "bg-blue_gray-100",
  },
};
const sizes = {
  xs: "h-[50px] px-[26px] text-[18px]",
  lg: "h-[60px] px-[34px] text-[22px]",
  xl: "h-[70px] px-[34px] text-[24px]",
  md: "h-[54px] px-2.5",
  sm: "h-[52px] px-[34px] text-[20px]",
};

const Button = ({
  children,
  className = "",
  leftIcon,
  rightIcon,
  shape,
  variant = "fill",
  size = "sm",
  color = "black_900_03",
  ...restProps
}) => {
  return (
    <button
      className={`${className} flex flex-row items-center justify-center text-center cursor-pointer whitespace-nowrap ${shape && shapes[shape]} ${size && sizes[size]} ${variant && variants[variant]?.[color]}`}
      {...restProps}
    >
      {!!leftIcon && leftIcon}
      {children}
      {!!rightIcon && rightIcon}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
  shape: PropTypes.oneOf(["round"]),
  size: PropTypes.oneOf(["xs", "lg", "xl", "md", "sm"]),
  variant: PropTypes.oneOf(["outline", "fill"]),
  color: PropTypes.oneOf(["black_900_02", "white_A700", "black_900_03", "gray_200", "blue_gray_100"]),
};

export { Button };
