import { Img, Button, Heading } from "../../components";
import React from "react";
import { Link } from "react-router-dom";

export default function InvestmentSection() {
  return (
    <>
      {/* Investment Section */}
      <div className="mt-[84px]">
        <div className="flex items-center bg-gray-100 md:flex-col">
          {/* Text and Button Section */}
          <div className="flex flex-1 flex-col items-start gap-[52px] pl-24 pr-14 lg:pl-8 md:self-stretch md:px-5 sm:gap-[26px]">
            <div className="flex flex-col items-start gap-6">
              {/* Heading */}
              <Heading
                size="heading2xl"
                as="h2"
                className="text-[64px] font-semibold leading-[111%] text-black-900_03 lg:text-[48px] md:text-[48px]"
              >
                <span className="font-light">
                  <>
                    Invest Capital in us
                    <br />
                  </>
                </span>
                <span>Get higher returns</span>
              </Heading>
            </div>
            {/* Button */}
                 <Link to="/contactus">
            <Button
              variant="outline"
              shape="round"
              className="min-w-[184px] rounded-[26px] !border px-[29px] font-medium uppercase sm:px-4"
            >
              Know more
            </Button>
            </Link>
          </div>
          {/* Image Section */}
          <Img
            src="images/img_image_7.png"
            alt="Imageseven"
            className="h-[686px] w-[44%] object-contain md:w-full"
          />
        </div>
      </div>
    </>
  );
}
