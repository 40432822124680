import { Text, Heading, RatingBar, Button, Img } from "./..";
import React from "react";

export default function UserReview({
  userName = "Ram Bahadur",
  reviewText = `"Fantastic app! The live tracking feature is accurate, and I love the various payment options available. The drivers are courteous and professional. This app has made commuting so much easier."`,
  ...props
}) {
  return (
    <div
      {...props}
      className={`${props.className} flex flex-col w-[34%] md:w-full gap-3.5 p-[26px] sm:p-4 bg-white-a700 shadow-xs rounded-[20px]`}
    >
      <div className="self-stretch">
        <div className="flex justify-center gap-4">
          <Button color="blue_gray_100" size="md" className="w-[54px] rounded-[10px] px-2.5">
            <Img src="images/img_lock.svg" />
          </Button>
          <div className="flex flex-1 flex-col items-start">
            <RatingBar
              value={5}
              isEditable={true}
              color="#f5b82c"
              activeColor="#f5b82c"
              size={16}
              className="relative z-[2] flex gap-2.5"
            />
            <Heading
              size="headingxs"
              as="h4"
              className="relative mt-[-2px] text-[24px] font-semibold text-black-900_02"
            >
              {userName}
            </Heading>
          </div>
        </div>
      </div>
      <Text size="textmd" as="p" className="mb-[42px] w-full text-[18px] font-light leading-[168%] !text-black-900_03">
        {reviewText}
      </Text>
    </div>
  );
}
