import { Helmet } from "react-helmet";
import { Text, Heading, Img } from "../../components";
import DeleteAccountSection from "./DeleteAccountSection";
import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

// Content object
const content = {
  accountDeletion: `
(Last updated on: 30 December 2024)

At S Mobility, we value your privacy and offer a simple process to delete your account along with all associated data. Below are the steps to request account deletion.

Option 1: Delete Your Account via the S Mobility App
- Open the S Mobility App: Launch the app on your smartphone.
- Go to Your Profile: Tap on the profile icon in the app’s menu to navigate to your profile page.
- Click on the pencil icon: Click on the pencil icon to edit your profile.
- Select ‘Delete Account’: On your edit profile page, locate and tap on the ‘Delete Account’ option.
- Confirm Deletion: Follow the on-screen instructions to confirm your account deletion request.

Once confirmed, the following data will be permanently deleted from our servers:
- Your name
- Your email address (if provided)
- Your mobile number
- Ride history
- Payment information
- Any other data linked to your account

Important Note: This process is irreversible. Once your data is deleted, it cannot be recovered.

Option 2: Request Account Deletion via Email
- Compose an Email: Send an email to saurabh.chandra@s-mobility.in.
- Include the Following Information:
  - Your full name
  - Your registered mobile number
  - A request to delete your account
- Verification Process: After receiving your request, we will verify your information to confirm the authenticity of the request.
- Data Deletion: Once verified, we will delete all data associated with your account, including:
  - Your name
  - Email address
  - Mobile number
  - Ride history
  - Payment details
  - Any other linked information

You will receive a confirmation email once the account deletion process is complete.

Additional Information:
- Data Recovery: After account deletion, your data cannot be restored. Please ensure you want to proceed before confirming.
- Processing Time: Account deletion requests made via email may take up to 7 business days to process.
- Need Assistance? If you have questions or encounter any issues during the process, please contact us at saurabh.chandra@s-mobility.in.

By providing these options, S Mobility ensures that you have full control over your data while offering a transparent and user-friendly account deletion process.
`,
};

export default function AccountDeletionPage() {
  return (
    <>
      <Helmet>
        <title>Account Deletion - S Mobility</title>
        <meta
          name="description"
          content="Learn how to delete your S Mobility account and manage your data with ease."
        />
      </Helmet>
      <div className="flex w-full flex-col gap-7 bg-white-a700">
        <div className="mr-1.5 flex flex-col gap-1 md:mr-0">
          <Header />
          <div className="relative h-[294px] content-center lg:h-auto md:h-auto">
            {/* Background Overlay */}
            <Img
              src="images/img_overlay.png"
              alt="Overlay"
              className="h-[284px] w-full flex-1 object-cover"
            />

            {/* Account Deletion Section */}
            <DeleteAccountSection />
          </div>
          <div className="flex items-center justify-center bg-[url(/public/images/img_content_section.png)] bg-cover bg-no-repeat py-[78px] lg:py-8 md:py-5 sm:py-4  ">
            <div className="container-xs flex justify-center px-14 lg:px-5 md:px-5">
              <div className="flex flex-col gap-[5px] lg:w-full md:w-full">
                {/* Dynamic Content */}
                {content.accountDeletion.split("\n").map((line, index) => (
                  <Text
                    key={index}
                    size="textxl"
                    as="p"
                    className=" text-[22px] font-normal leading-[33px] !text-black-900 lg:text-[18px]"
                  >
                    {line}
                  </Text>
                ))}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
