import { Text, Img } from "./..";
import React from "react";

export default function CarProfile({
  carImage = "images/img_car.svg",
  carDescription = "Regular car washing intervals",
  ...props
}) {
  return (
    <div
      {...props}
      className={`${props.className} flex items-center w-full gap-5 sm:gap-3`}
    >
      {/* Icon Wrapper */}
      <div className="flex h-[70px] w-[70px] items-center justify-center rounded-full bg-gray-100">
        <Img
          src={carImage}
          alt="Feature Icon"
          className="h-[40px] w-[40px] object-contain"
        />
      </div>

      {/* Description */}
      <Text
        size="text2xl"
        as="p"
        className="text-[18px] font-medium leading-[1.5] !text-black-900"
      >
        {carDescription}
      </Text>
    </div>
  );
}
