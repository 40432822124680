import { Helmet } from "react-helmet";
import { Text, Heading, Img } from "../../components";
import PrivacyPolicySection from "./PrivacyPolicySection";
import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

// Content object
const contentOld = {
  privacy: `
(Last updated on: 29 December 2024)

The terms "We" / "Us" / "Our"/ “Company” individually and collectively refer to SAURABHCHANDRA AUTOMOTIVE PRIVATE LIMITED and the terms "You" / "Your" / "Yourself" refer to the Users. The term “Services” refers to any services and/or facilities offered by SAURABHCHANDRA AUTOMOTIVE PRIVATE LIMITED.

This Privacy Policy is an electronic record in the form of an electronic contract formed under the Information Technology Act, 2000 and the rules made thereunder and the amended provisions pertaining to electronic documents / records in various statutes as amended by the Information Technology Act, 2000. This Privacy Policy does not require any physical, electronic or digital signature.

This Privacy Policy is a legally binding document between You and the Company. The terms of this Privacy Policy will be effective upon Your acceptance of the same (directly or indirectly in electronic form, by signing up on the “Mobile App” or using the Company’s “Website” or by other means) and will govern the relationship between You and the Company for Your use of the Mobile App and Website. For the purposes of this Privacy Policy the term “Mobile App” will include various versions of software application available for specific users (including drivers) that is owned, managed and/or operated either by the Company or through its parent company, its subsidiaries or its affiliates.

This document is published and shall be construed in accordance with the provisions of the Information Technology (reasonable security practices and procedures and sensitive personal data of information) rules, 2011 under Information Technology Act, 2000; that require publishing of the Privacy Policy for collection, use, storage and transfer of personal information including sensitive personal data or information.

Please read this Privacy Policy carefully. By using the Mobile App or Website, You indicate that You understand, agree and consent to this Privacy Policy. If You do not agree with the terms of this Privacy Policy, please do not use the Mobile App and Website.

By providing Us Your Information (as described in para 1 below) or by making use of the facilities provided by Us, You hereby consent to the collection, storage, processing and transfer of any or all of Your “Personal Information” and “Non-Personal Information” by Us as specified under this Privacy Policy. You further agree that such collection, use, storage and transfer of Your Information shall not cause any loss or wrongful gain to You or any other person.


TYPE OF YOUR INFORMATION COLLECTED

To avail certain Services on Our Mobile App and Website, users may be required to provide certain information (whether at the time onboarding or during the term of You using Our Website, Mobile App and/or Services) namely: ▪a) Your mobile number, b) Your name, c) Your email address, d) Your geo-location, home address, work address, e) Your gender, f) Your date of birth and Your credit card or debit card or bank account details, occupation, interests, and the like.In case of user of driver’s version of the Mobile App, users may be required to provide certain additional information such as: ▪g) Government issued identity documents such as driving license, PAN, Aadhaar, etc., h) information of Your secondary contact or reference. Your Information as supplied enables Us to, amongst others, improve Our Services and provide You the most user-friendly experience.

Any information will not be considered as personal information including sensitive personal data or information if it is freely available and accessible in the public domain or is furnished under the Right to Information Act, 2005 or any other law for the time being in force.

In addition to any Personal Information or other information that You choose to submit to Us, we and Our third party service provider may use a variety of technologies that automatically (or passively) collect certain information whenever You visit or interact with the Services, Website or Mobile App (“Usage Information”). This Usage Information may include the browser that You are using, the URL that referred You to Our Services, all of the areas within Our Services that You visit and interact with, among other information.

PURPOSE FOR COLLECTION AND USAGE OF YOUR INFORMATION

All required information is Service dependent and we may use the above said User information to/for:, a) provide, maintain, protect, and improve Our Services (including advertising Our current or proposed products and services) b) developing new services,, c) process payments, and d) communicate with you in connection with Services, promotions and advertisements.

We may also use your information to provide functionality, analyze performance, fix errors, and improve usability and effectiveness of Our Services. When you use Our voice, image and/or camera services, We use Your voice, image, video inputs and other personal information to respond to Your request, provide / process the Service, and improve Our Services

We will use the Personal Information You have chosen to provide Us for the purpose for which You provided it and those identified above. We will not use it for any other purpose without Your consent.As mentioned above, We might on occasions, use this information to notify You of any important changes or any special promotions that may be of interest to You. You can opt out from receiving such material at any time by emailing Us and asking to be removed from the notification or mailing list.

INFORMATION SHARING

There will be occasions where it will be necessary for Us to disclose Your Personal Information to third parties, where we employ or obtain facilities or services of other companies and individuals to perform functions on Our behalf. We may be required to disclose Your Personal Information to third parties to provide the Services.Examples include sending postal mails and e-mails, analyzing data, processing payments, transmitting content, and providing customer or partner services. However, the disclosure will only be made where it is necessary to fulfil the purpose for which You disclosed Your Personal Information or for provision of end-to-end Services. Otherwise than stated in this Clause 3, we do not disclose Personal Information that You may give Us to any organisation or person outside Our Company, unless You have authorized Us to do so.

Unless otherwise disclosed in this Privacy Policy, We will share the Personal Information including sensitive Personal Information to any third party without obtaining the prior consent of the User in the following circumstances:
(a) When it is requested or required by law or by any court or governmental agency or authority to disclose, for the purpose of verification of identity, or for the prevention, detection, investigation including cyber incidents, or for prosecution and punishment of offences. These disclosures are made in good faith and belief that such disclosure is reasonably necessary for enforcing these Terms; for complying with the applicable laws and regulations.
(b) We propose to share such information within Our group companies and officers and employees of such group companies for the purpose of processing Personal Information on Our behalf. We also ensure that these recipients of such information agree to process such information based on Our instructions and in compliance with this Privacy Policy and any other appropriate confidentiality and security measures.

INFORMATION SECURITY

We take appropriate security measures to protect against unauthorized access to or unauthorized alteration, disclosure or destruction of data. These include internal reviews of Our data collection, storage and processing practices and security measures, including appropriate encryption and physical security measures to guard against unauthorized access to systems where we store personal data.

The information collected via Our Mobile App and Website is securely stored in Our controlled database. This database is housed on servers protected behind a firewall, with access restricted via password. Despite stringent security measures, no system is entirely immune to breaches. Hence, We cannot assure the absolute security of Our database or guarantee the interception-free transmission of information supplied to Us over the Internet.

GRIEVANCE REDRESSAL

Redressal Mechanism: Any complaints, abuse or concerns with regards to content and or comment or breach of these terms shall be immediately informed to the designated Grievance Officer as mentioned below via in writing or through email signed with the electronic signature to Chief Grievance Officer.

Chief Grievance Officer
SAURABHCHANDRA AUTOMOTIVE PRIVATE LIMITED

D-1b/538 Ground Floor, Sangam Vihar
New Delhi ▪110080
India (IN)
Email: saurabh.chandra@s-mobility.in


CHANGES TO PRIVACY POLICY

From time to time we may amend or update this Privacy Policy. When this occurs, we will post the new version of the Privacy Policy on Our Mobile App and Website. You can periodically review this Privacy Policy so that You remain informed as to how we are protecting Your Personal Information.
`,
};

export default function PrivacyPolicyPage() {
  const parseContent = (text) => {
    return text.split("\n").map((line, index) => {
      if (line.trim() === "") {
        // Render a blank space for empty lines
        return <div key={index} className="h-4" />;
      } else if (line.startsWith("\t")) {
        // Indented text
        return (
          <Text
            key={index}
            size="textlg"
            as="p"
            className="ml-4 text-[20px] font-normal leading-[30px] !text-black-900 lg:text-[18px]"
          >
            {line.trim()}
          </Text>
        );
      } else if (line.startsWith("▪")) {
        // Bullet points
        return (
          <li
            key={index}
            className="text-[20px] font-normal leading-[30px] !text-black-900 lg:text-[18px] list-disc ml-6"
          >
            {line.substring(1).trim()}
          </li>
        );
      } else if (line.startsWith("# ")) {
        // Headings (H1)
        return (
          <h1
            key={index}
            className="text-[28px] font-bold leading-[36px] !text-black-900 lg:text-[24px]"
          >
            {line.substring(2).trim()}
          </h1>
        );
      } else if (line.startsWith("## ")) {
        // Subheadings (H2)
        return (
          <h2
            key={index}
            className="text-[24px] font-semibold leading-[32px] !text-black-900 lg:text-[20px]"
          >
            {line.substring(3).trim()}
          </h2>
        );
      } else if (line.startsWith("**") && line.endsWith("**")) {
        // Bold text
        return (
          <Text
            key={index}
            size="textxl"
            as="p"
            className="text-[22px] font-bold leading-[33px] !text-black-900 lg:text-[18px]"
          >
            {line.substring(2, line.length - 2).trim()}
          </Text>
        );
      } else {
        // Regular text
        return (
          <Text
            key={index}
            size="textxl"
            as="p"
            className="text-[22px] font-normal leading-[33px] !text-black-900 lg:text-[18px]"
          >
            {line}
          </Text>
        );
      }
    });
  };

  return (
    <>
      <Helmet>
        <title>Privacy Policy - S Mobility</title>
        <meta
          name="description"
          content="Learn how S Mobility protects your privacy and handles your data."
        />
      </Helmet>
      <div className="flex w-full flex-col gap-7 bg-white-a700">
        <div className="mr-1.5 flex flex-col gap-1 md:mr-0">
          <Header />
          <div className="relative h-[294px] content-center lg:h-auto md:h-auto">
            {/* Background Overlay */}
            <Img
              src="images/img_overlay.png"
              alt="Overlay"
              className="h-[284px] w-full flex-1 object-cover"
            />

            {/* Privacy Policy Section */}
            <PrivacyPolicySection />
          </div>
          <div className="flex items-center justify-center bg-[url(/public/images/img_content_section.png)] bg-cover bg-no-repeat py-[78px] lg:py-8 md:py-5 sm:py-4">
            <div className="container-xs flex justify-center px-14 lg:px-5 md:px-5">
              <div className="flex flex-col gap-[5px] lg:w-full md:w-full">
                {/* Dynamic Content */}
                <div className="text-container space-y-4">
                  {parseContent(content.privacy)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}


const content = {
  privacy: `
(Last updated on: 11 January 2025)
# Privacy Policy Notice

**I. Introduction**
**II. Overview**
**III. Data collections and uses**
**IV. Choice and transparency**
**V. Legal information**

# I.	Introduction
This Privacy Policy applies to the use of services provided by "S Mobility". The term "S Mobility" refers to the brand name under which the services are offered, while the legal entity operating these services is SAURABHCHANDRA AUTOMOTIVE PRIVATE LIMITED. This Privacy Policy describes how S Mobility and its affiliates collect and use data.
The terms "We," "Us," and "Our" in this Privacy Policy refer to SAURABHCHANDRA AUTOMOTIVE PRIVATE LIMITED, operating under the brand name "S Mobility." By using our app, website, or related services, you agree to the terms of this Privacy Policy.This Privacy Policy is an electronic record in the form of an electronic contract formed under the Information Technology Act, 2000 and the rules made thereunder and the amended provisions pertaining to electronic documents / records in various statutes as amended by the Information Technology Act, 2000. This Privacy Policy does not require any physical, electronic or digital signature.
This Privacy Policy is a legally binding document between You and the Company. The terms of this Privacy Policy will be effective upon Your acceptance of the same (directly or indirectly in electronic form, by signing up on the “Mobile App” or using the Company’s “Website” or by other means) and will govern the relationship between You and the Company for Your use of the Mobile App and Website. For the purposes of this Privacy Policy the term “Mobile App” will include various versions of software application available for specific users (including drivers) that is owned, managed and/or operated either by the Company or through its parent company, its subsidiaries or its affiliates.
This document is published and shall be construed in accordance with the provisions of the Information Technology (reasonable security practices and procedures and sensitive personal data of information) rules, 2011 under Information Technology Act, 2000; that require publishing of the Privacy Policy for collection, use, storage and transfer of personal information including sensitive personal data or information.

# II.	Overview

**A.	Scope**
This notice applies to users of S Mobility’s apps, websites, and other services in India.
This notice describes how S Mobility and its affiliates collect and use data. 
This notice applies to all S Mobility users of India. 
This notice specifically applies to:
▪ Riders: those who request or receive transportation and related services via their S Mobility account.
▪ Drivers: those who provide transportation to Riders individually via their S Mobility account or through partner transportation companies.
▪ Guest users: those without an S Mobility account who receive rideservices ordered by other S Mobility account owners
▪ Business customers (collectively, “Enterprise Customers”); or by friends, family members or other individual account owners;

This notice also governs S Mobility’s other collections of data in connection with its services. For example, we may collect contact information of owners I.e contact information of those who manage and use accounts owned by Enterprise Customers; or data of those who start but do not complete their applications to be drivers.
All those subject to this notice are referred to as “users” in this notice.
Our privacy practices are subject to applicable laws in the places in which we operate. This means that we engage in the practices described in this notice in a particular country or region only if permitted under the laws of those places.

# III.	Data collections and uses

S Mobility collects data:
\t1.	provided by users to S Mobility
\t2.	created during use of our services
\t3.	from other sources

Please see here for a summary of the data we collect and how we use it. S Mobility collects the following data from these sources:
**1.	Data provided by users. This includes:**
▪ Account information: We collect data when users create or update their S Mobility accounts. This includes first and last name, email, phone number, login name and password, address, profile picture, payment or banking information (including related payment verification information), user settings, and loyalty program information for S Mobility partners that includes Referral ID.

For drivers, this also includes vehicle or insurance information, emergency contact information to provide services using S Mobility apps.
▪ Background check information (drivers): This includes information submitted during the driver person application process, such as driver history or criminal record (where permitted by law), license status, known aliases, prior addresses, and right to work. This information may be collected by S Mobility service providers.
▪Identity verification documents and photos: This includes government issued identification such as driver’s license or passports (which may contain identification photos and numbers, date of birth, and gender), and user-submitted photos such as selfies and profile pictures.
▪Demographic data: We collect demographic data such as birth date/age, gender or occupation when necessary to enable certain features, or provide access to age-restricted products or services. For example, we collect users’ date of birth or age to verify eligibility to use S Mobility Wallet,We also collect or infer (using first name) gender information to enable female or non-binary users to designate their preference for providing or receiving services to/from female or non-binary users.
We also collect demographic data, such as age group and household composition, through user surveys.
▪ User content: We collect data (including chat logs and call recordings) when users contact S Mobility customer support, provide ratings or feedback for users, restaurants or merchants, use features that enable users to upload content or submit recordings (including in-app audio recordings or dashboard cameras recordings), or otherwise contact S Mobility.
Please see here (riders) and here (drivers)for more information about how ratings provided by other users are determined and used.
▪Travel information: We collect travel itinerary information, including times and dates of upcoming flight, lodging or car rental reservations, from users of our S Mobility Travel feature. We collect such information when users manually input their information into their S Mobility Travel itinerary, or from travel-related email confirmations if authorized by users to access their email accounts. If so authorized, S Mobility will only access users’ email accounts to collect travel itinerary information to enable the S Mobility Travel feature, and will adhere to the applicable email provider policies, including Google’s API Services User Data Policy. Please see here for information regarding how users may remove S Mobility’s access to their email accounts via the S Mobility app or through their email service provider settings.
▪Payment-related data collected from users, such as card details, UPI IDs, transaction IDs, and banking information, will be stored and processed only on servers located in India in compliance with RBI’s data localization guidelines.
**2.	Data created during use of our services. This includes:**
▪ Location data (driver): We collect precise and approximate location data from drivers’ and delivery persons’ mobile devices when the S Mobility app is running in the foreground (app open and on-screen) or background (app open but not on-screen).
▪ Location data (riders). We collect precise and/or approximate location information from riders’ and order recipients’ mobile devices if they enable us to do so via their device settings.
S Mobility collects such data from the time a rider is requested until it is finished, and any time the app is running in the foreground (app open and on-screen). See “Choice and transparency” below for information on how riders can enable precise location data collection.
Riders may use the S Mobility apps without enabling collection of location data from their mobile devices. However, this may affect certain features in the S Mobility apps. For example, a rider who has not enabled precise location data will have to manually enter their pickup address.
In addition, precise location data collected from a driver’s device during a trip is linked to the rider’s account, even if the rider has not enabled precise location data to be collected from their device. This data is used for purposes such as customer support, fraud detection, insurance, litigation and receipt generation.
▪ Transaction information: We collect transaction information related to the use of our services, including the type of services requested or provided; trip or order details (such as date and time, requested pick-up and drop off addresses, distance traveled); and payment transaction information (merchant's name and location, amount charged, and payment method). We also associate a user’s name with that of anyone who uses their promotion code.
▪ Usage data: We collect data about how users interact with our services. This includes access dates and times, app features or pages viewed, browser type, and app crashes and other system activity.
▪ Device data: We collect data about the devices used to access our services, including the hardware models, device IP address or other unique device identifiers, operating systems and versions, software, preferred languages, advertising identifiers, device motion data, and mobile network data.
▪ Communications data: We collect data regarding communications between users that are enabled through S Mobility’s apps. This includes communication type (phone, text or in-app message), date/time, and content (including recordings of phone calls solely when users are notified of the recording in advance).

**3. Data from other sources. These include:**
▪ users participating in our referral programs. For example, when a user refers another person, we receive the referred person’s data from that user.
▪ S Mobility account owners who request services for or on behalf of other users (such as friends or family members), or who enable other users to request or receive services through their business accounts (such as Enterprise
Customers).
▪ users or others providing information in connection with claims or disputes.
▪ S Mobility business partners through which users create or access their S Mobility account, such as payment providers, social media services, or apps or websites that use S Mobility’s APIs or whose APIs S Mobility uses.
▪ service providers who help us verify users’ identity, background information, and eligibility to work, detect fraud, and screen users in connection with sanctions, anti-money laundering, or know-your-customer requirements.
▪ partner transportation companies (for drivers who use our services through an account associated with such a company).
▪ publicly available sources.
▪ marketing partners and service providers, including banks in connection with cash back programs, and data resellers.
▪ law enforcement officials, public health officials, and other government authorities.

**S Mobility uses the data we collect:**
**1.	To provide our services. S Mobility uses data to provide, personalize, maintain, and improve our services.**
\tThis includes using data to:
▪create/update accounts.
▪enable transportation, delivery and other services/features, such as:
▪using location data to navigate rider pick-ups, calculate ETAs, and track the progress of rides.
▪enabling features that involve data sharing, such as sharing driver first name and vehicle information with riders to facilitate pick-ups, or features that enable ETA sharing.
▪matching available drivers to users requesting services, including based on personal data such as location and proximity to other users, and user settings / preferences (such as preferred destinations), and non-personal data such as vehicle type requested.
▪enabling accessibility features.
▪calculating prices and fares, including using location data and trip or order details (such as requested pick-up and drop off addresses). We may also consider non-personal data or factors, including date and time, estimated distance and time, minimum base fares, tolls, taxes and fees, and surge pricing.
▪process payments, and enable payment and e-money products such as S Mobility Wallet
▪facilitate invoicing
▪provide users with trip updates, generate receipts, and inform them of changes to our terms, services, or policies.
▪perform necessary operations to maintain our services, including to troubleshoot software bugs and operational problems.
S Mobility performs the above activities on the grounds that they are necessary to fulfill the terms of our agreements with users, are compatible with such uses, or are necessary for purposes of S Mobility’s and its users’ legitimate interests.

**2. Safety, fraud protection and security. We use data to help maintain the safety, security, and integrity of our services and users. This includes:**
▪verifying users' accounts, identity or compliance with safety requirements.
For example, we review driver person background checks (including criminal history where required or permitted by law) to verify their identities and eligibility to provide transportation or deliveries.
We process user profile pictures, government-issued identification photos and numbers, or other user-submitted photographs to perform this verification. We also use such technology to prevent fraudulent use of identification photos, or to prevent users from creating multiple accounts.
Please see here for more information regarding account and identity verification.
▪using customer service information (including reports of safety incidents), device data (e.g., to detect speeding or harsh braking / acceleration), transaction, and usage data to identify potentially unsafe drivers and driving. This can lead to drivers and delivery persons receiving messages encouraging safer driving, and/or account deactivation following human review.
▪using account, device, location, usage, transaction, wireless carrier, and other data, including communications between users and metadata, to prevent, detect, and combat fraud, including by guest users.
▪using reported incidents, user ratings*, and other feedback to encourage safe use of S Mobility’s platform and compliance with our terms and as grounds for deactivating users with low ratings.
▪using driver data (such as past trip information and reported incident rates) and rider data (such as account information, cancellation and reported incident rates, current pick-up and drop-off location, past trip information, and ratings information) to predict and help avoid pairings of users that may result in increased risk of conflict.* We also avoid pairings where one user has previously given the other a low (e.g., 1 star) rating.
▪using location, phone number, user name, vehicle details and other relevant information to provide live support from safety experts during trips or deliveries.
The fraud and unsafe driving prevention and detection activities described above may be considered profiling under applicable laws, and can result in deactivation of users (generally only after human review). For information regarding how to object to the above activities, please see “Choice and transparency” below.
S Mobility performs the above activities on the grounds that they are necessary to fulfill the terms of our agreements with users, and/or for purposes of the legitimate safety and security interests of S Mobility, our users and members of the general public.

**3. Customer support. We use the information we collect (which may include chat logs) to provide customer support, including to investigate and address user concerns and to monitor and improve our customer support responses and processes.**
S Mobility performs the above activities on the grounds that they are necessary to fulfill the terms of our agreements with users or for purposes of S Mobility’s legitimate interests in monitoring and improving its customer support services.

**4.	Research and development. We use data for analysis, machine learning, product development, research, and testing. This helps us make our services more convenient and easy-to-use, enhance the safety and security of our services, and develop new services and features.**
S Mobility performs the above activities on the grounds that they are necessary for purposes of S Mobility’s legitimate interests in improving and developing new services and features.

**5.	Enabling communications between users. For example, a driver may message or call a rider to confirm a pick-up location, a rider may call a driver to retrieve a lost item.**
S Mobility performs the above activities on the grounds that they are necessary to fulfill the terms of our agreements with users.

**6.	Marketing and Advertising. S Mobility uses data (other than guest users’ data) to market its services, and those of S Mobility partners.**
We specifically use account, approximate location, device and usage data, and trip and order history to provide ads and marketing communications that are personalized based on users’ observed or inferred location, interests and characteristics (which may include inferred gender*).
This includes using this data to:
▪send emails, text messages, push notifications, and in-app messages or other communications marketing or advertising S Mobility products, services, features, offers, promotions, news and events. For example, we may send push notifications, or in-app messages offering discounts or promo.
▪display S Mobility advertising on third party apps or websites.
S Mobility performs the above activities on the grounds that they are necessary for purposes of S Mobility’s legitimate interests in informing users about S Mobility services and features or those offered by S Mobility partners. See the sections titled “Choice and transparency” and “Marketing and advertising choices” for information on users’ choices regarding how S Mobility may use their data for marketing and advertising.
**7. Non-marketing communications. S Mobility may use data to send surveys and other communications that are not for the purpose of marketing the services or products of S Mobility or its partners.**
S Mobility performs the above activities on the grounds that they are necessary to fulfill the terms of our agreements with users, or for purposes of S Mobility’s and its users’ legitimate interests in informing users about events that may have an impact on their use of S Mobility’s services.

**8. App Permissions and Purpose**
S Mobility apps require specific permissions to function effectively and provide a seamless user experience. Below is a detailed breakdown of the permissions requested by the Customer App and Driver App, along with the purpose of each.

**Customer App Permissions**
	1.	ACCESS_FINE_LOCATION:
	•	Reason: To provide accurate real-time location-based services, including ride pick-up and drop-off navigation.
	•	Usage: Enables precise location tracking for assigning nearby drivers and calculating trip distances.
	2.	ACCESS_COARSE_LOCATION:
	•	Reason: For location approximation when fine location is unavailable.
	•	Usage: Ensures app functionality in low-accuracy mode to determine general pickup and drop-off areas.
	3.	INTERNET:
	•	Reason: Required to fetch data, enable Google Maps integration, and communicate with S Mobility servers.
	•	Usage: Ensures real-time updates for ride status, payments, and other functionalities.
	4.	VIBRATE:
	•	Reason: To provide haptic feedback for notifications and alerts.
	•	Usage: Alerts users of ride updates, incoming messages, or notifications.
	5.	SCHEDULE_EXACT_ALARM:
	•	Reason: For scheduling precise notifications or ride reminders.
	•	Usage: Used to ensure users are alerted on time for scheduled rides.
	6.	SYSTEM_ALERT_WINDOW:
	•	Reason: To display overlays for critical app-related notifications (e.g., low battery, ride alerts).
	•	Usage: Provides notifications without interrupting other tasks on the device.

**Driver App Permissions**
	1.	ACCESS_BACKGROUND_LOCATION:
	•	Reason: Enables continuous location tracking, even when the app is minimized or not actively used.
	•	Usage: Ensures trip tracking, driver safety, and accurate fare calculation.
	2.	ACCESS_FINE_LOCATION:
	•	Reason: For precise GPS-based navigation and pick-up/drop-off point accuracy.
	•	Usage: Facilitates real-time route optimization and customer location tracking.
	3.	ACCESS_COARSE_LOCATION:
	•	Reason: For approximate location tracking when fine location is not available.
	•	Usage: Provides location data in scenarios with low GPS accuracy.
	4.	CAMERA:
	•	Reason: Allows drivers to upload vehicle documents, selfies, or proof of delivery/pick-up.
	•	Usage: Ensures compliance and enhances driver verification.
	5.	FOREGROUND_SERVICE:
	•	Reason: Enables location updates when the app is in use.
	•	Usage: Facilitates live trip tracking during rides.
	6.	INTERNET:
	•	Reason: For app-to-server communication and fetching live updates.
	•	Usage: Enables real-time updates for trips, fares, and other app data.
	7.	READ_EXTERNAL_STORAGE:
	•	Reason: Allows the app to access uploaded documents or images from the device.
	•	Usage: Ensures seamless document verification and profile updates.
	8.	WRITE_EXTERNAL_STORAGE:
	•	Reason: Enables saving downloaded documents, such as receipts or trip details.
	•	Usage: Provides record-keeping functionality for drivers.
	9.	RECORD_AUDIO:
	•	Reason: Facilitates in-app voice notes or communication features if enabled.
	•	Usage: Improves driver support and communication where applicable.
	10.	SCHEDULE_EXACT_ALARM:
	•	Reason: Ensures timely reminders for rides or schedule updates.
	•	Usage: Enhances operational efficiency and punctuality.
	11.	SYSTEM_ALERT_WINDOW:
	•	Reason: To display critical app-related alerts or notifications.
	•	Usage: Allows uninterrupted alerts for ride or system-related updates.
	12.	VIBRATE:
	•	Reason: Provides feedback for notifications.
	•	Usage: Alerts drivers of new trip requests, ride cancellations, or navigation updates.

# C. Cookies and third-party technologies
S Mobility and its partners use cookies and other identification technologies on our apps, websites, emails, and online ads for purposes described in this notice, and S Mobility’s Cookie Notice.
Cookies are small text files that are stored on browsers or devices by websites, apps, online media, and ads. S Mobility uses cookies and similar technologies for purposes such as:
▪authenticating users
▪remembering user preferences and settings
▪delivering and measuring the effectiveness of advertising campaigns
▪analyzing site traffic and trends, and generally understanding the online behaviors and interests of people who interact with our services
Please see our Cookie Notice for more information regarding the use of cookies and other technologies described in this section.

# D. Data sharing and disclosure
Some of S Mobility’s services and features require that we share data with other users, or at users’ request or with their consent. We may also share such data with our affiliates, subsidiaries, and partners, for legal reasons or in connection with claims or disputes.

S Mobility may share data:
**1.	With other users**
This includes sharing:
▪riders’ first name, rating, and pickup and/or dropoff locations with drivers.
▪riders’ first name with other riders in a carpool trip. Riders in carpool trips may also see the other riders’ dropoff location.
▪for drivers we may share data with the rider(s), including name and photo; vehicle make, model, color, license plate, and vehicle photo; location (before and during trip); average rating provided by users; total number of trips; period of time since they signed up to be a driver or delivery person; contact information; and driver person profile, including compliments and other feedback submitted by past users.
We also provide riders with receipts containing information such as a breakdown of amounts charged, driver or delivery person first name, photo, and route map. We also include other information on those receipts if required by law.
In addition, if a user creates an account using an email address affiliated with an S Mobility for Business account owner (i.e., their employer), we may use, and share their profile data (such as name and email address) with such account owner, to help that user expense trips or orders to that S Mobility for Business account.*
▪for those who participate in S Mobility’s referral program, we share certain data of referred users, such as trip count, with the user who referred them as necessary to determine the referral bonus.
**2.	At users’ request or with users’ consent**
This includes sharing data with:
▪General public. Questions or comments from users submitted through public forums such as S Mobility blogs and S Mobility social media pages may be viewable by the public, including any data included in the questions or comments submitted by a user.

**3. With S Mobility subsidiaries and affiliates**
We share data with our subsidiaries and affiliates to help us provide our services or conduct data processing on our behalf. 

**4. With S Mobility service providers and business partners**
These include the third parties, or categories of third parties, listed below. Where a third party is identified, please see their linked privacy notices for information regarding their collection and use of personal data.
▪payment processors and facilitators, including Razorpay
▪background check, identity verification and risk solutions providers.
▪cloud storage providers.
▪customer support platform and service providers.
▪Google, in connection with the use of Google Maps in S Mobility’s apps.
▪marketing partners and marketing platform providers, including social media advertising services, advertising networks, third-party data providers,
and other service providers to reach or better understand our users and measure advertising effectiveness.
▪research partners, including those performing surveys or research projects in partnership with S Mobility or on S Mobility’s behalf.
▪service providers that assist S Mobility to enhance the safety and security of S Mobility apps and services.
▪service providers that provide us with artificial intelligence and machine learning tools and services.
▪accountants, consultants, lawyers, and other professional service providers.
▪insurance and financing partners.
▪insurance companies, in connection with insurance claims made or reported by a user relating to S Mobility’s services, and for the purpose of adjusting or handling the insurance claim.
▪airports.
▪third-party vehicle suppliers, including fleet and rental partners.

**5. For legal reasons or in the event of a dispute**
S Mobility may share users’ data if we believe it’s required by applicable law, regulation, operating license or agreement, legal process or governmental request, insurance policy, or where the disclosure is otherwise appropriate due to safety or similar concerns.
This includes sharing data with law enforcement officials, public health officials, other government authorities, airports (if required by the airport authorities as a condition of operating on airport property), insurance companies, or other third parties as necessary to enforce our Terms of Service, user agreements, or other policies; to protect S Mobility’s rights or property or the rights, safety, or property of others; or in the event of a claim or dispute relating to the use of our services. In the event of a dispute relating to use of another person’s credit card, we may be required by law to share a user’s data, including trip or order information, with the owner of that credit card.

**6. With consent**
S Mobility may share a user’s data other than as described in this notice if we notify the user and they consent to the sharing.
S Mobility retains user data for as long as necessary for the purposes described above, which varies depending on data type, the category of user to whom the data relates, the purposes for which we collected the data, and whether the data must be retained after an account deletion request for the purposes described below.
For example, we retain data:
▪for the life of users’ accounts if such data is necessary to provide our services.
E.g., account data.
▪for 7 years if necessary to comply with tax requirements. E.g., drivers’ trip or location information.
Users may request deletion of their account through the Edit Profile Page -> Delete Account Link in the S Mobility app, or through S Mobility’s website (riders here; drivers and delivery persons here).
Following an account deletion request, we delete the user’s account and data, except as necessary for purposes of safety, security, fraud prevention or compliance with legal requirements, or because of issues relating to the user’s account (such as an outstanding credit or an unresolved claim or dispute). For drivers, this generally means that we retain certain of their data for as long as necessary for actual or potential tax, litigation, or insurance claims. For rider, we generally delete data within 90 days of an account deletion request, except where retention is necessary for the above reasons.

# IV.	Choice and transparency
S Mobility enables users to access and/or control data that S Mobility collects, including through:
▪	privacy settings
▪	device permissions
▪	in-app ratings pages
▪	marketing and advertising choices

S Mobility also enables users to request access to or copies of their data, make changes or updates to their accounts, request deletion of their accounts, or request that S Mobility restrict its processing of user data.
**1.	Privacy settings**
The Account > Settings > Privacy menu in the S Mobility app allows riders and order recipients to set or update their preferences regarding location data collection and sharing, emergency data sharing, and notifications. Our Privacy Center is also available in a web version.
▪Location data collection (riders )
Riders can enable/disable S Mobility’s collection of their mobile device location data through their device settings, which can be accessed via the Account > Settings > Privacy > Location Sharing menu.
▪Emergency Data Sharing
Riders may enable S Mobility’s sharing of their mobile device real-time location data with emergency police, fire, and ambulance services. This includes sharing approximate location at the time the emergency call was placed; the car’s make, model, color, and license plate information; the rider’s name and phone number; pickup and dropoff locations; and the driver’s name.
Riders may enable/disable this feature via the Account > Settings > Privacy > Location Sharing menu, or the Safety Center.
Drivers can also enable/disable Emergency Data Sharing via the App settings > Emergency Data Sharing menu, or the Safety Toolkit.
▪Notifications: account and trip updates
S Mobility provides users with trip status notifications and updates related to activity on their account. These notifications are a necessary part of using the S Mobility app and cannot be disabled. However, users may choose the method by which they receive these notifications through the Account > Settings > Privacy menu.
▪Notifications: discounts and news
Users may enable S Mobility to send push notifications about discounts and news from S Mobility. Users may control whether they receive push notifications here or via S Mobility’s Privacy Center.
▪Third-party app access
Users may authorize third-party applications to access their S Mobility account data to enable additional features. Users can review / withdraw access by third-party applications here or via S Mobility’s Privacy Center.
**2. Device permissions**
Most mobile device platforms (iOS, Android, etc.) have defined certain types of device data that apps cannot access without the device owner’s permission, and these platforms have different methods for how that permission can be obtained. Users should check the available settings on their devices, or check with their
provider.

**3. User data requests**
S Mobility provides users with a variety of ways to learn about, control, and submit questions and comments about S Mobility’s handling of their data. In addition to the methods indicated below, users may also submit data requests via our Privacy Inquiry Form (riders and order recipients here, drivers and delivery persons here).
▪Data access and portability: Depending on where they are located, users may have the right to “access” their data (meaning, to be informed of the data that S Mobility has collected about them), and to “portability” of their data (meaning, to receive a copy of such data). Regardless of their location, S Mobility provides several options for viewing and obtaining copies of the data S Mobility has collected about them.
Users can access data including their profile data and trip or order history through the S Mobility apps or via S Mobility’s website.
Our Explore Your Data feature allows users to view an online summary of certain information about their account, such as number of trips or orders, rating, rewards status, and number of days since they’ve been an S Mobility user.
Our Download Your Data feature allows users to download a copy of the most requested data relating to use of S Mobility, including account, usage, communications, and device data.
▪Changing or updating data: Users can edit the name, phone number, email address, payment method, and profile picture through the Settings menu in S Mobility’s apps or driver portal.
▪Deleting data: Users may request deletion of their account through S Mobility Website Account Deletion Page, or in the Edit Profile Page in the S Mobility apps by clicking on Delete Account link.
▪Objections, restrictions, and complaints: Users may request that we stop using all or some of their data, or that we limit our use of their data. This includes objecting to our use of data that is based on S Mobility’s legitimate interests. S Mobility may continue to process data after such objection or request to the extent required or permitted by law.
In addition, depending on their location, Users may have the right to file a complaint relating to S Mobility’s handling of their data with the data protection authority in their country. For example, users in the EU and Latin America may submit such requests to the data protection authorities in the country in which

# V.	Legal information
**A.	Data controllers and Data Protection Officer**
SaurabhChandra Automotive Private Limited is controller of the data processed in connection with use of S Mobility’s services
Users may submit requests to exercise their rights regarding their data (riders and drivers and delivery persons.
Users may also contact S Mobility's Data Protection Officer regarding issues relating to S Mobility's processing of their personal data, and their data protection rights.
**Grievance Redressal Process:**
▪Users can submit complaints via the app or email to the Chief Grievance Officer at saurabh.chandra@s-mobility.in
▪Complaints will be acknowledged within 3 business days and resolved within 30 business days.
▪If unresolved, users may escalate the issue to the relevant data protection authority.”

**Chief Grievance Officer**
SAURABHCHANDRA AUTOMOTIVE PRIVATE LIMITED
D-1b/538 Ground Floor, Sangam Vihar
New Delhi ▪110080
India (IN)
Email: saurabh.chandra@s-mobility.in

**B.	Legal Framework for Data Transfers**
S Mobility operates, and processes user data, globally. We comply with applicable legal frameworks relating to the transfer of data.
S Mobility leverages Google Firebase as its Backend-as-a-Service. While Firebase’s infrastructure may process some data in the United States or other global locations, all payment-related and sensitive personal data is stored and processed on servers located in India, in compliance with RBI’s data localization guidelines. S Mobility ensures that all cross-border data transfers are conducted in accordance with applicable Indian data protection laws.
This includes processing of your data on S Mobility’s servers in the United States, and transferring or enabling access to your data globally, in order to:
▪provide you services wherever you request them.
▪provide you access to your information, such as trip / order history, wherever you request it.
▪provide access to and responses from S Mobility’s customer service agents.
▪respond to requests for information by governments or law enforcement, as necessary.
S Mobility is committed to protecting our users’ personal data regardless of where they are located or where, or by who, their personal data is processed. This includes implementing global measures to protect users’ data, including:
▪securing user data when in transit, including through encryption, and at rest.
▪mandating company-wide training regarding privacy and data security.
▪implementing internal policies and procedures to limit access to, and use of, users’ data.
▪limiting government and law enforcement access to user data, except where required by law, there are imminent threats to safety, or users’ have consented to the access. 
Please note the following:
▪	Access: Users have the right to access their personal data that is subject to S Mobility’s DPF certification. For information on how to exercise this right, please see “Choice and transparency” above.
▪	Onward Transfer: S Mobility is responsible for the transfer of personal data, subject to its certification to third parties. For information regarding the parties to whom S Mobility may transfer personal data, please see “Data sharing and disclosure” above.
▪	Request from law enforcement: S Mobility is required under applicable law to share user data, including that which may be subject to S Mobility’s certification, pursuant to legal process or governmental request, including from law enforcement.
All cross-border transfers of personal data are conducted in compliance with Indian data protection laws. For payment-related data, we adhere to the RBI’s data localization guidelines, ensuring such data is stored within India.

# C. Data Breach Notification
In the event of a data breach involving your personal data, we will notify affected users and relevant authorities within a reasonable timeframe, as required by applicable laws. Notifications will include details of the breach, potential impact, and steps being taken to mitigate harm.

# D. Updates to this Privacy Notice
We may occasionally update this notice. If we make significant changes, we will notify users in advance of the changes through the S Mobility apps or through other means, such as email. We encourage users to periodically review this notice for the latest information on our privacy practices.
Use of our services after an update constitutes consent to the updated notice to the extent permitted by law.`,
};