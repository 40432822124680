import React from "react";

const sizes = {
  textxs: "text-[14px] font-normal",
  texts: "text-[16px] font-normal lg:text-[13px]",
  textmd: "text-[18px] font-light lg:text-[15px]",
  textlg: "text-[20px] font-normal lg:text-[17px]",
  text2xl: "text-[22px] font-normal lg:text-[18px]",
  text3xl: "text-[24px] font-normal lg:text-[20px] md:text-[22px]",
  text4xl: "text-[26px] font-normal lg:text-[22px] md:text-[24px] sm:text-[22px]",
};

const Text = ({ children, className = "", as, size = "textlg", ...restProps }) => {
  const Component = as || "p";

  return (
    <Component className={`text-white-a700 font-inter ${className} ${sizes[size]} `} {...restProps}>
      {children}
    </Component>
  );
};

export { Text };
