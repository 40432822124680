import { Img, Heading, Button } from "../../components";
import React, { useState } from "react";

const fleetData = [
  {
    type: "Hatchback",
    image: "images/img_image_9.png",
    title: "Wagon R",
  },
  {
    type: "Sedan",
    image: "images/swift-dzire.png",
    title: "Swift Dzire",
  },
  {
    type: "MUV",
    image: "images/ertiga.png",
    title: "Ertiga",
  },
];

export default function FleetSection() {
  const [selectedCarType, setSelectedCarType] = useState("Hatchback");
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleTypeChange = (type) => {
    const newIndex = fleetData.findIndex((car) => car.type === type);
    setSelectedCarType(type);
    setCurrentIndex(newIndex);
  };

  const handleArrowClick = (direction) => {
    let newIndex = currentIndex;
    if (direction === "left") {
      newIndex = currentIndex === 0 ? fleetData.length - 1 : currentIndex - 1;
    } else if (direction === "right") {
      newIndex = currentIndex === fleetData.length - 1 ? 0 : currentIndex + 1;
    }
    setCurrentIndex(newIndex);
    setSelectedCarType(fleetData[newIndex].type);
  };

  return (
    <>
      {/* Fleet Section */}
      <div className="mt-[76px] flex justify-center">
        <div className="container-xs flex justify-center lg:px-5 md:px-5">
          <div className="flex w-full flex-col items-center">
            {/* Section Title */}
            <Heading
              as="h2"
              className="text-[48px] font-semibold text-black-900_02 lg:text-[40px] md:text-[32px] sm:text-[26px]"
            >
              Our Fleet
            </Heading>

            {/* Car Type Buttons */}
            <div className="mt-9 flex gap-10 md:flex-col">
              {fleetData.map((car) => (
                <Button
                  key={car.type}
                  shape="round"
                  color={
                    selectedCarType === car.type ? "black_900_03" : "gray_200"
                  }
                  className={`min-w-[202px] rounded-[26px] px-[34px] font-medium sm:px-4 ${
                    selectedCarType === car.type ? "!text-white" : "!text-black"
                  }`}
                  onClick={() => handleTypeChange(car.type)}
                >
                  {car.type}
                </Button>
              ))}
            </div>

            {/* Carousel Section */}
            <div className="relative mt-[58px] h-[606px] self-stretch">
              {/* Arrow and Title Section */}
              <div className="absolute bottom-px left-0 right-0 mx-auto flex flex-1 items-start justify-between gap-5">
                {/* Left Arrow */}
                <div
                  className="mb-72 w-[6%] rounded-[40px] bg-gray-100 px-2.5 py-4 cursor-pointer"
                  onClick={() => handleArrowClick("left")}
                >
                  <Img
                    src="images/img_arrow_down.svg"
                    alt="Arrow Left"
                    className="h-[48px] w-full lg:h-auto md:h-auto"
                  />
                </div>

                {/* Car Title */}
                <Heading
                  size="headingmd"
                  as="h3"
                  className="self-end text-[40px] font-semibold text-black-900_02 lg:text-[34px] md:text-[34px] sm:text-[32px]"
                >
                  {fleetData[currentIndex].title}
                </Heading>

                {/* Right Arrow */}
                <div
                  className="w-[6%] rounded-[40px] bg-gray-100 px-2.5 py-4 cursor-pointer"
                  onClick={() => handleArrowClick("right")}
                >
                  <Img
                    src="images/img_arrow_left.svg"
                    alt="Arrow Right"
                    className="h-[48px] w-full lg:h-auto md:h-auto"
                  />
                </div>
              </div>

              {/* Car Image */}
              <Img
                src={fleetData[currentIndex].image}
                alt={fleetData[currentIndex].title}
                className="absolute left-0 right-0 top-0 mx-auto h-[508px] w-[54%] object-contain"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
