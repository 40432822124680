import { Helmet } from "react-helmet";
import { Text, Heading, Img } from "../../components";
import RefundAndCancellationSection from "./RefundSection";
import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

// Content object
const content = {
  refund: `
Cancellations

Cancellations can be frustrating for riders and driver partners alike. In order to ensure that driver partners are fairly compensated for their time when committing to a trip, we have ensured that a cancellation fee is in place.

When will a cancellation fee be charged?

For scheduled rides and rentals, a cancellation fee of ₹50 is charged for:

Cancelling after the driver is assigned
If you cancel after a driver is assigned, you will be charged the cancellation fee.

Cancellation due to no show
If the driver’s wait time exceeds 20 minutes at pickup location, you’re marked a no-show and charged the cancellation fee.

A No-Show fee of ₹100 will be charged in case you don’t show up for the ride.

For outstation rides, a cancellation fee of ₹100 is charged:

If the trip is cancelled within 1 hour before the scheduled pickup time; or
If the driver’s wait time exceeds 45 minutes at the pickup location and you’re subsequently marked a no-show and charged the cancellation fee.
A No-Show fee of ₹100 will be charged in case you don’t show up for the ride.

How can you clear your cancellation fee?

The cancellation fee for a cancelled trip would appear as a negative balance to be cleared before you book your next trip. While booking a new ride, we provide you with an option to add the money to wallet and continue with the booking experience without much hindrance.

What can you do if you were charged a cancellation fee unfairly?

If you feel that you have been charged a cancellation fee unfairly and due to factors beyond your control like the driver cancelling the trip or if you’re unable to connect to the driver, you could raise a concern to our support team by following these simple steps:

Go to Profile Page > Complain 
Type ‘Review my cancellation fee’ in subject
Submit details related to your case in the text box provided
Sit back and relax, our support team will reach out to you if any questions arise. You’d be notified about the progress of your query via email notifications.

To avoid cancelling your ride, ensure before requesting

Your pickup location is correct
Always double-check your pickup location on the map before confirming your trip. Pinning your location accurately will save time for both you and your driver.

You’re ready for your ride
We notify you when your driver is en-route. Our driver partners do their best to arrive at the pickup location a few minutes before the scheduled time, so be ready to hop on to your ride when it arrives.

Waiting Fee

Our driver-partners make every effort to arrive at your pickup location before the scheduled time. As a rider, it’s a good practice to be on time for a scheduled trip.

Moreover, an efficient dispatch enables us to give you a more cost-efficient & reliable ride.Towards this effort, riders will be charged a nominal waiting fee of ₹3/min when the driver’s wait time:

Exceeds 5 minutes at the pickup location for a scheduled ride or rental; or
Exceeds 30 minutes at the pickup location for an outstation trip; or
Exceeds 2 minutes at an intermediate stop of a multi-stop ride
How it works

For scheduled rides and rentals:

Your driver-partner arrives at the pickup location. Once there, a push notification is sent to you communicating his / her arrival.
The driver-partner will wait for 5 minutes from the original scheduled pickup time.
If the ride doesn’t start within 5 minutes, waiting fee charges will kick in.
If after a total wait time of 20 minutes, the ride hasn’t started, you will be marked a no-show.
For outstation:

Your driver-partner arrives at the pickup location. Once there, a push notification is sent to you communicating his / her arrival.
The driver-partner will wait for 30 minutes from the original scheduled pickup time.
If the ride doesn’t start within 30 minutes, waiting fee charges will kick in.
If after a total wait time of 45 minutes, the trip hasn’t started, you will be marked a no-show.
For multi-stop rides:

You arrive at an intermediate stop. Once there, a push notification is sent to you communicating your arrival and requesting you to be back within 2 minutes.
The driver-partner will wait for 2 minutes after arrival.
If the ride is not resumed within 2 minutes, waiting fee charges will start kicking in and continue until your ride is resumed.
If after a total wait time of 15 minutes, the ride is not resumed, the driver-partner will call you to confirm if you are coming back and subsequently end the trip if required. Your waiting charges are incurred until the driver-partner ends the trip.
`,
};

export default function RefundAndCancellationPage() {
  return (
    <>
      <Helmet>
        <title>Refund & Cancellation Policy - S Mobility</title>
        <meta
          name="description"
          content="View the refund and cancellation policies for S Mobility's cab booking services."
        />
      </Helmet>
      <div className="flex w-full flex-col gap-7 bg-white-a700">
        <div className="mr-1.5 flex flex-col gap-1 md:mr-0">
          <Header />
          <div className="relative h-[294px] content-center lg:h-auto md:h-auto">
            {/* Background Overlay */}
            <Img
              src="images/img_overlay.png"
              alt="Overlay"
              className="h-[284px] w-full flex-1 object-cover"
            />

            {/* Refund & Cancellation Section */}
            <RefundAndCancellationSection />
          </div>
          <div className="flex items-center justify-center bg-[url(/public/images/img_content_section.png)] bg-cover bg-no-repeat py-[78px] lg:py-8 md:py-5 sm:py-4">
            <div className="container-xs flex justify-center px-14 lg:px-5 md:px-5">
              <div className="flex flex-col gap-[5px] lg:w-full md:w-full">
                {/* Dynamic Content */}
                {content.refund.split("\n").map((line, index) => (
                  <Text
                    key={index}
                    size="textxl"
                    as="p"
                    className=" text-[22px] font-normal leading-[33px] !text-black-900 lg:text-[18px]"
                  >
                    {line}
                  </Text>
                ))}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
