import React from "react";
import { useRoutes } from "react-router-dom";
import Home from "pages/Home";
import NotFound from "pages/NotFound";
import Home1 from "pages/Home1";
import ContactUs from "pages/ContactUs";
import TermsAndConditionsPage from "pages/Terms";
import PrivacyPolicyPage from "pages/PrivacyPolicy";
import RefundAndCancellationPage from "pages/Refund";
import DeleteAccountPage from "pages/DeleteAccount";

const ProjectRoutes = () => {
  let element = useRoutes([
    { path: "/", element: <Home1 /> },
    { path: "*", element: <NotFound /> },
    {
      path: "home1",
      element: <Home1 />,
    },
    {
      path: "contactus",
      element: <ContactUs />,
    },
    {
      path: "terms",
      element: <TermsAndConditionsPage />,
    },
    {
      path: "privacy",
      element: <PrivacyPolicyPage />,
    },
    {
      path: "refund",
      element: <RefundAndCancellationPage />,
    },
    {
      path: "delete-account",
      element: <DeleteAccountPage />,
    },
  ]);

  return element;
};

export default ProjectRoutes;
