import { Heading } from "../../components";
import React from "react";

export default function PrivacyPolicySection() {
  return (
    <>
      <div className="absolute bottom-0 left-0 right-0 top-0 m-auto flex h-[284px] flex-1 items-start justify-center bg-[url(/public/images/img_group_2.png)] bg-cover bg-no-repeat py-11 lg:h-auto md:h-auto md:py-5 sm:py-4">
        <div className="container-xs mb-14 flex px-[52px] lg:px-5 md:px-5">
          <Heading
            size="heading3xl"
            as="h1"
            className="text-[85px] font-semibold uppercase text-white-a700"
          >
            Privacy Policy
          </Heading>
        </div>
      </div>
    </>
  );
}
