import { Helmet } from "react-helmet";
import { Text, Heading, Img } from "../../components";
import TermsSection from "./TermsSection";
import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";

const termsAndConditionsContent = `

(Last updated on: 29 December 2024)
This document is an electronic record in terms of the Information Technology Act, 2000 and rules made thereunder. Further, this electronic record is generated by a computer system and does not require any physical or digital signatures. This electronic record is published in accordance with the relevant provisions of the Information Technology Act, 2000 and rules made thereunder.


1. ELECTRONIC MEDIA

1.1 Please note that (a) the domain name https://s-mobility.in (the “Website”); and (b) the software application for use on small, wireless computing devices such as smartphones and tablets and also for use on desktop or laptop computers (“Application”) ((a) and (b) hereinafter collectively referred to the “Electronic Media” or "Platform") is owned, controlled, managed and/or operated by SAURABHCHANDRA AUTOMOTIVE PRIVATE LIMITED, a private limited company incorporated in accordance with the laws of India, either itself and/or through its subsidiaries or its affiliates (hereinafter collectively referred to as the (“Company”).

1.2 SAURABHCHANDRA AUTOMOTIVE PRIVATE LIMITED (hereinafter referred to as "S Mobility") is a subsidiary of the Company and holds a license under Applicable Law to operate as an Aggregator.


2. LEGAL AGREEMENT

2.1 These terms and conditions of use (‘’Terms of Use’’) govern the use and access of Electronic Media and availing of the Services (defined below) on the Electronic Media; and constitutes a legal agreement ("Agreement") between S Mobility, and you/yourself/yourselves (“User(s)”). If you continue to use and/or access registration or avail the Services offered by S Mobility as Aggregator on the Platform and browse the Electronic Media, you are agreeing to comply with and be bound by the Terms of Use.

2.2 In addition to the aforementioned, your use and access to the Electronic Media and the Services provided by S Mobility through the Electronica Media, is subject to the guidelines, rules, privacy policy, terms, conditions and/or other applicable policies and procedures (“S Mobility's Policies”) applicable to such Service, which shall be posted on the Electronic Media from time to time. Unless otherwise provided for in any specific S Mobility Policy, the S Mobility Policies shall be deemed to be incorporated into this Terms of Use and shall be considered as part and parcel of hereof. Therefore, by impliedly or expressly agreeing to be bound by the Terms of Use, you also agree to be bound by the Company Policies, as may be updated, amended or revised from time to time.

2.3 If you do not agree with the Terms of Use, please do not access and use the Electronic Media related to S Mobility or our Service (defined later) through all other means.

2.4 S Mobility reserves the right, to modify or replace, in part or full, any of these Terms of Use, or change, suspend, block, discontinue or restrict your use to all or any feature of the Service or Electronic Media at any time.

2.5 S Mobility shall not be required to notify the User of any changes made to these Terms of Use. The revised Terms of Use shall be made available on the Electronic Media. The User is requested to visit the Website and/or Application to view the most current Terms of Use. The User can determine when S Mobility last modified the Terms of Use by referring to the "Last Updated" legend above. It shall be User’s responsibility to check these Terms of Use periodically for changes. S Mobility may require the User to provide User’s consent to the updated Terms of Use in a specified manner prior to any further use of the Electronic Media and the Services. If no such separate consent is sought, User’s continued use of the Electronic Media, following the changes to the Terms of Use , will constitute User’s acceptance of those changes. User’s use of the Electronic Media and the Services is subject to the most current version of the Terms of Use made available on the Electronic Media at the time of such use.


3. DEFINITIONS

All the defined and capitalized terms in these Terms of Use will have the meaning assigned to them here below:

3.1 "Aggregator" means a person who has been issued a license under Applicable Law digital intermediary or market place for a passenger to connect with a driver for the purposes of transportation.

3.2 "Account" shall mean the account created by the User on the Electronic Media for availing the Services provided by S Mobility.

3.3 “Additional Fee” shall mean such fee as may be charged additionally, while availing the Services for the Ride from Third Party Providers, towards consumption of any additional services like wi-fi services, partner service provider’s services, food items, water, beverages etc.

3.4 "Applicable Law(s)" shall mean and include all applicable statutes, enactments, acts of legislature or parliament, laws, ordinances, rules, by-laws, regulations, notifications, guidelines, policies, directions, directives and orders of any governmental authority, tribunal, or a court of India, including interalia the Motor Vehicles Act, 1988 and Motor Vehicle Aggregator Guidelines, 2020

3.5 "Application" shall mean the mobile application "S Mobility" updated by S Mobility from time to time.

3.6 "S Mobility" or "us" or "we" or "our" shall mean SAURABHCHANDRA AUTOMOTIVE PRIVATE LIMITED, a company incorporated under the provisions of the Companies Act, 2013 and having its registered office at D-1b/538 Ground Floor, Sangam Vihar, New Delhi - 110080, India (IN), which expression shall, unless it be repugnant to the context or meaning thereof, be deemed to mean and include all its successors, affiliates and permitted assigns.

3.7 "Cancellation Fee" shall mean such fee as charged for all the cancelled Rides as notified to the User through the mode of booking levied in terms of Clause 11 for the cancellation terms.

3.8 "City of Operation" shall mean a city in which the Users avail and render the transportation Services respectively.

3.9 "Company" shall mean SAURABHCHANDRA AUTOMOTIVE PRIVATE LIMITED, a company incorporated under the provisions of the Companies Act, 2013 and having its corporate office at D-1b/538 Ground Floor, Sangam Vihar, New Delhi - 110080, India (IN), which expression shall, unless it be repugnant to the context or meaning thereof, be deemed to mean and include all its successors, affiliates and permitted assigns.

3.10 "Driver(s)" shall mean and include such individuals who are independent third-party service providers engaged by or associated with the Company or its subsidiaries/affiliates, to provide the transportation services to the User(s) on the Platform. They are persons who are registered with S Mobility after having represented to S Mobility that they have necessary licenses to provide transportation services within the City of Operation.

3.11 "E-Wallet" shall mean a pre-paid instrument, which shall refer to the User’s linked PayTM wallet or the S Mobility Wallet wherein the User can add money through the various payment methods offered by the available Payment Gateway or Payment Processor.

3.12 "Force Majeure Event" shall mean any event arising due to any cause beyond the reasonable control of S Mobility.

3.13 "Location Fee" shall mean and include without limitation any toll duty, inter-state taxes, airport charges or any other charge/fee imposed by the governmental instrumentalities or any third party with regard to operation of the Vehicle (defined below) at specific locations.

3.14 "Registration Data" shall mean and may include the present, valid, true and accurate name, email ID, phone number and such other information as may be required by S Mobility from the User from time to time for registration on the Application.

3.15 "Ride" shall mean the travel in the Vehicle by the User facilitated through the Electronic Media including interalia the City Ride or Airport Ride, as the case may be.

3.16 "Surge Charges" means the fee payable by a User for a Ride conducted during the undermentioned days and time slots ("Surge Hours") as mentioned in the Pricing feature of the mobile app.

3.17 "Relaxed Hour Fee" means the fee to be charged for a Ride conducted by the Driver outside Surge Hours.

3.18 "Service(s)" shall mean the provision of technology platform services available on/through the Electronic Media that enables the User’s operating the Electronic Media, who are desirous of obtaining, availing, arranging, scheduling transportation services offered by independent third party service providers including inter alia the Drivers who are under an agreement with S Mobility or with certain S Mobility’s affiliates (“Third Party Providers”). For avoidance of any doubt, it is clarified that it also includes the facilitation of transportation service between the User and the Driver by S Mobility through the Application or via a telephone request at the call center of S Mobility, or booking on the Website, within the City of Operation, as elaborated in Clause 8.

3.19 "Total Ride Fee" shall mean and include the aggregate amount reflected in the Electronic Media or notified to the User as an amount payable towards the transportation services and the Services provided through the Electronic Media including inter alia the Location Fee, the Additional Fee, Surge Charges, Relaxed Hour Fee, Waiting Fee (as applicable) and any other fee as may be notified by S Mobility in the Electronic Media or through mode of booking for each User inclusive of any/all taxes as may be applicable from time to time.

3.20 "Terms of Use" shall mean these terms and conditions for the User.

3.21 “Third- Party Advertiser(s)” online or offline advertisements of various sponsors advertising and marketing their own goods and services through S Mobility.

3.22 "User/ You" means a person who has an Account on the Application and/or Website.

3.23 "Vehicle" shall mean four-wheeler auto mobile as defined under the Motor Vehicles Act, 1988.

3.24 "Website" shall mean the Application and the domain name https://s-mobility.in operated by the Company or any other software that enables the use of the Application or such other URL as may be specifically provided by the Company.

3.25 "Waiting fee" shall mean such fee as charged for the waiting time of a driver, at the rider’s pickup location and/or intermediate stops, in excess of the committed time as notified to the User.


4. REGISTRATION AND ACCOUNT

4.1 The User hereby understands and acknowledges that he/she has attained at least 18 (eighteen) years of age and is competent to contract under the Applicable Law(s) and thereby can register on the Electronic Media after complying with the requirements of this Clause 4 and by entering their Registration Data.

4.2 The User shall ensure that the Registration Data provided by him/her in the Account is accurate, complete, current, valid and true and is updated from time to time. S Mobility shall bear no liability for false, incomplete, old or incorrect Registration Data provided by the User.

4.3 The User is solely responsible for maintaining the confidentiality of his/her Registration Data and will be liable for all activities and transactions that occur through the User’s Account, whether initiated by the User or any third party. It has been agreed that the User shall not not authorize any third parties to use his/her Account, and may not allow any persons under the age of 18 to receive transportation services from Third Party Providers unless they are accompanied by the User himself/herself. The User’s Account cannot be transferred or assigned to a third party. S Mobility shall not be liable for any third party claim with respect to any loss that the User may have incurred as a result of someone else using the User’s password or Account, either with or without User’s knowledge or any third party have incurred upon usage of Services provided by S Mobility through the User’s account, whether with or without User’s knowledge.

4.4 S Mobility reserves the right to suspend or terminate User’s Account with immediate effect and for an indefinite period, if S Mobility has a reason to believe that the Registration Data or any other data provided by the User is incorrect or false, or that the security of the User’s Account has been compromised in any way, or there is a breach of terms and conditions of these Terms of Use or upon any unauthorised use of User’s Account by any third party or for any other reason the User may find just or equitable.

4.5 It is the User’s responsibility to check and to ensure that the User downloads the correct application for his/her device. S Mobility shall not be liable if the User does not have a compatible mobile device or if the User downloads the wrong version of the Application for his/her mobile device.

4.6 S Mobility allows the User to open only one Account in association with the Registration Data provided by the Users. In case of any unauthorized use of the User’s Account please immediately inform S Mobility at saurabh.chandra@s-mobility.in .

4.7 In case, User is unable to access his/her Account, the User needs to inform S Mobility at saurabh.chandra@s-mobility.in and make a written request for blocking the User Account. The Company will not be liable for any unauthorized transactions made through the User’s Account prior to the expiry of 72 (seventy two) hours after the User has made a request in writing for blocking the User Account, and shall not have any liability in case of a Force Majeure Event.

4.8 S Mobility reserves the right to modify, terminate or suspend the Services to the User at any time without prior notice due to any changes in internal policy or due to the Applicable Laws or any breach of these Terms of Use by the User or for any reason whatsoever. The User may terminate his/her Account by submitting a request to S Mobility at saurabh.chandra@s-mobility.in or by calling on +91-8007005005. However, in either case, all previous service requests shall stand voided, and no refund or Service-related deliverable shall be provided. S Mobility will make every effort to respond to the User’s request for termination at the earliest. However, the User will remain responsible for all the transactions that occurred prior to termination of User’s Account.


5. USER CONTENT

5.1 Except for the Registration Data or any other data provided, posted, uploaded or submitted by the User on the Electronic Media during the use of any of the service offered through the Electronic Media, S Mobility does not want the User to, and the User should not, send any confidential or proprietary information to S Mobility on the Electronic Media or otherwise, unless otherwise is required by Applicable Laws. In accepting these Terms of Use, the User agrees that any information or materials that the User or individuals acting on the User’s behalf provide to S Mobility through the Electronic Media other than the aforementioned will not be considered confidential or proprietary.

5.2 S Mobility will be entitled to process and transfer User’s information as and when it deems fit and it may store or transfer user’s information in a server outside India or the country where the User is located in order to perform S Mobility’s obligations under this Terms of Use, S Mobility Policies and Applicable Laws.

5.3 The User agrees to grant S Mobility a non-exclusive, worldwide, perpetual, irrevocable, royalty-free, sub-licensable (through multiple tiers) right to exercise the copyright, publicity, database rights or any other rights which the User has in User’s information, in any media now known or not currently known, with respect to User’s information. THE USER AGREES AND PERMITS S Mobility TO SHARE USER’S INFORMATION WITH THIRD PARTIES IN ACCORDANCE WITH THE APPLICABLE LAWS AND PRIVACY POLICY.

5.4 Further, S Mobility shall store the information provided by the User including without limitation the recordings of User’s calls between S Mobility's executive and the User for their conversation regarding the Services of S Mobility or any/all Service-related matters. The User shall promptly inform S Mobility on any change in the information provided by the User to S Mobility in respect to the Services availed on the S Mobility platform.

5.5 S Mobility reserves the right, but have no obligation, to monitor the content posted or the programme content materials posted on the Electronic Media. S Mobility shall have the right to remove or edit any content that in its sole discretion violates, or is alleged to violate, any Applicable Law or either the spirit or letter of these Terms of Use. Notwithstanding this right of S Mobility, User remains solely responsible for the content of the materials he/she may post on the Electronic Media and in the private messages (as applicable). Please be advised that such content posted does not necessarily reflect S Mobility’s views. In no event shall S Mobility assume or have any responsibility or liability for any content posted/shared or for any claims, damages or losses resulting from use of content and/or appearance of content on the Electronic Media. The User hereby represents and warrants that he/she has all necessary rights in and to all content the User provides and all information it contains and that such content shall not infringe any proprietary or other rights of third parties or contain any libelous, tortious, or otherwise unlawful information.


6. PRIVACY

6.1 When you become a User, then and during your use of the Electronic Media and/or Services, you will provide us with certain information and other data as aforesaid that may or may not be otherwise publicly available. We will respect the privacy of such data as per our Privacy Policy which is available at https://s-mobility.in/privacyPolicy. We will only collect, use, disclose or process your data in accordance with our Privacy Policy, which you accept by your continued use of the Electronic Media and/or the Services. We will share your data with other parties only as set out in our Privacy Policy.

6.2 In addition to and not in contravention of the terms, measures and procedures regarding storing and securing the data and information on the Electronic Media as stipulated in the Privacy Policy, you acknowledge, accept and agree that S Mobility utilizes standard security configurations for securing data and information on the Electronic Media which is reasonable and sufficient security practice and procedure as per the industry standard and for the purposes of Information Technology Act, 2000 and the rules and regulations made thereunder. The User agree that in case of any leakage, destruction, unauthorized access or the like to information stored on S Mobility's Electronic Media, they waive their right to file a complaint for compensation under all Applicable Laws.


7. USE OF ELECTRONIC MEDIA

7.1 The User agrees, undertakes and confirms that User’s use of Electronic Media shall be strictly governed by the following binding principles:

7.1.1 User shall not host, display, upload, modify, publish, transmit, update or share any information that:

(a) belongs to another person and to which User do not have any right to; or interferes with another user's use and enjoyment of the Electronic Media or any other individual's user and enjoyment of similar services; or

(b) that is harmful, harassing, blasphemous, defamatory, obscene, pornographic, libelous, invasive of another's privacy, hateful, or ethnically objectionable, disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever, or unlawfully threatening or unlawfully harassing including but not limited to "indecent representation of women" within the meaning of the Indecent Representation of Women (Prohibition) Act, 1986, of another person; or

(c) misleading in any way; or

(d) is patently offensive to the online community, such as sexually explicit content, or content that promotes obscenity, pedophilia, racism, bigotry, hatred or physical harm of any kind against any group or individual; or

(e) involves the transmission of "junk mail," "chain letters," or unsolicited mass mailing or "spamming"; or

(f) infringes upon or violates any third party's rights, (including, but not limited to, intellectual property rights, rights of privacy (including without limitation unauthorized disclosure of a person's name, email address, physical address or phone number) or rights of publicity]; or

(g) contains restricted or password-only access pages, or hidden pages or images (those not linked to or from another accessible page); or

(h) provides material that exploits people in a sexual, violent or otherwise inappropriate manner or solicits personal information from anyone; or

(i) provides instructional information about illegal activities such as making or buying illegal weapons, violating someone's privacy, or providing or creating computer viruses; or

(j) contains video, photographs, or images of another person without his or her express written consent and permission or the permission or the consent of his/ her guardian in the case of minor; or

(k) tries to gain unauthorized access or exceeds the scope of authorized access to the Electronic Media or to profiles, blogs, communities, account information, bulletins, friend request, or other areas of the Electronic Media or solicits passwords or personal identifying information for commercial or unlawful purposes from other users; or

(l) engages in commercial activities and/or sales without S Mobility’s prior written consent such as contests, sweepstakes, barter, advertising and pyramid schemes, or the buying or selling of "virtual" items related to the Electronic Media. Throughout these Terms of Use, our "prior written consent" means a communication coming from S Mobility’s authorized representative, specifically in response to User’s request, and specifically addressing the activity or conduct for which User’s may seek authorization; or

(m) interferes with another user's use and enjoyment of the Electronic Media or any other individual's user and enjoyment of similar services; or

(n) refers to any website or URL that, in S Mobility’s sole discretion, contains material that is inappropriate for the Electronic Media, contains content that would be prohibited or violates the letter or spirit of these Terms of Use; or

(o) harm minors in any way; or

(p) is fraudulent or involve the sale of counterfeit or stolen items; or

(q) violates any law for the time being in force; or

(r) deceives or misleads the addressee/ users about the origin of such messages or communicates any information which is grossly offensive or menacing in nature; or

(s) impersonate another person; or

(t) contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer resource; or contains any trojan horses, worms, time bombs, cancelbots, easter eggs or other computer programming routines that may damage, detrimentally interfere with, diminish value of, surreptitiously intercept or expropriate any system, data or personal identifiable information or attempt to interfere with the proper working of the Electronic Media or any transaction being conducted on the Electronic Media; or

(u) threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognizable offence or prevents investigation of any offence or is insulting to any other nation; or

(v) is false, inaccurate or misleading; or directly or indirectly, offer, attempt to offer, trade or attempt to trade in any item, the dealing of which is prohibited or restricted in any manner under the provisions of any Applicable Law, rule, regulation or guideline for the time being in force; or

(w) creates liability for S Mobility or cause S Mobility to lose (in whole or in part) the services of it’s vendors.

7.1.2 The User shall not use any "deep-link", "page-scrape", "robot", "spider" or other automatic device, program, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any portion of the Electronic Media or any content, or in any way reproduce or circumvent the navigational structure or presentation of the Electronic Media or any content, to obtain or attempt to obtain any materials, documents or information through any means not purposely made available through the Electronic Media. We reserve the right to bar any such activity as and when detected or deemed appropriate.

7.1.3 The User shall not probe, scan or test the vulnerability of the Electronic Media or any network connected to the Electronic Media nor breach the security or authentication measures on the Electronic Media or any network connected to the Electronic Media. The User may not reverse look-up, trace or seek to trace any information on any other user of or visitor to the Electronic Media, or any other customer of S Mobility, including any of S Mobility’s account not owned by the User, to its source, or exploit the Electronic Media or any Service or information made available or offered by or through the Electronic Media, in any way where the purpose is to reveal any information, including but not limited to personal identification or information, other than User’s own information, as provided for by the Electronic Media.

7.1.4 The User agrees he/she will not take any action that imposes an unreasonable or disproportionately large load on the infrastructure of the Electronic Media or S Mobility’s systems or networks, or any systems or networks connected to the Electronic Media.

7.1.5 The User may not use the Electronic Media or any content for any purpose that is unlawful or prohibited by these Terms of Use, or to solicit the performance of any illegal activity or other activity that infringes the rights of S Mobility or others.

7.1.6 The User shall not use the services or Electronic Media in any manner that could damage, disable, overburden, block or impair any of the server connected to the Electronic Media. The User may not attempt to gain unauthorized access to the services through hacking, password mining or any other means.

7.1.7 The User may not reverse engineer, decompile and disassemble any software used to provide the Services by S Mobility.

7.1.8 The User shall not make any negative, denigrating or defamatory statement(s) or comment(s) about S Mobility or the brand name or domain name used by S Mobility or the Services provided by S Mobility or the Third Party Providers or otherwise engage in any conduct or action that might tarnish the image or reputation, of S Mobility or its merchants on platform or otherwise tarnish or dilute any of S Mobility’s trade or service marks, trade name and/or goodwill associated with such trade or service marks, trade name as may be owned or used by S Mobility.

7.1.9 The User, by creating an Account under the provisions of these Terms of Use, authorizes us to call or SMS or email on his/ her registered mobile number/ registered email address for the purposes of communicating information pertaining to the Services, Rides, feedback or offers or providing customer support. The Users shall also have the option to request S Mobility to send him/her information regarding service, discounts and promotions provided by it. If the User requires S Mobility to provide him/her with information regarding offers, discounts and promotions relating to the services availed by him/her, click "Yes", otherwise click "No". If User clicks "Yes", we or an authorised representative, shall provide the above information to the User by way of call or an SMS or email to the User’s registered mobile number/registered email id. The User also has the option to discontinue receiving such information at any point of time. To discontinue receiving such information, User may at any point of time visit the specific link provided on Electronic Media to discontinue the same. Solely to enable S Mobility to use the information so that it is not violating any rights Users might have in the Information, User hereby agrees to grant S Mobility a non-exclusive, worldwide, perpetual, irrevocable, royalty-free, sub-licensable (through multiple tiers) right to exercise the copyright, publicity, database rights or any other rights User has the information, in any media now known or not currently known, with respect to User’s information. We will only use User’s information in accordance with these Terms of Use and the Privacy Policy.

7.1.10 The User shall not engage in advertising to, or solicitation of, other users of the Electronic Media to buy or sell any products or services, including, but not limited to, services related being displayed on or related to the Electronic Media. It shall be As further described in this Terms of Usea violation of these Terms of Use to use any information obtained from the Electronic Media in order to harass, abuse, or harm another person, or in order to contact, advertise to, solicit, or sell to another person outside of the Electronic Media.

7.1.11 The User understands that S Mobility always has the right to disclose any information (including the identity of the persons providing information or materials on the Electronic Media) as necessary to satisfy any law, regulation or valid governmental request. This may include, without limitation, disclosure of the information in connection with investigation of alleged illegal activity or solicitation of illegal activity or in response to a lawful court order or subpoena. In addition, S Mobility can (and User hereby expressly authorize S Mobility to) disclose any information about User to law enforcement or other government officials, as it, in S Mobility’s sole discretion, believe necessary or appropriate in connection with the investigation and/or resolution of possible crimes, especially those that may involve personal injury.

7.1.12 It is possible those other users (including unauthorized users or “hackers”) may post or transmit offensive or obscene materials on the Electronic Media and that User may be involuntarily exposed to such offensive and obscene materials. It also is possible for others to obtain personal information about Users on the public forum due to User’s use of the Electronic Media, and that the recipient may use such information to harass or injure the User. S Mobility does not approve of such unauthorized uses but by using the Electronic Media. The User acknowledges and agree that S Mobility shall not responsible for the use of any personal information that the User publicly discloses or shares with others on the Electronic Media. The User should carefully select the type of information that he/she publicly discloses or shares with others on the Electronic Media, or from the Electronic Media on other social platforms (e.g., but not limited to Facebook).

7.1.13 S Mobility shall have all the rights to take necessary action and claim damages that may occur due to User’s involvement/participation in any way through User or through group/s of people, intentionally or unintentionally causing hindrance in provision of Services.

7.1.14 S Mobility’s performance of these Terms of Use is subject to existing laws and legal processes of Government of India, and nothing contained in the Terms of Use is in derogation of S Mobility's right to comply with law enforcement requests or requirements relating to User’s use of this Service or information provided to or gathered by S Mobility with respect to such use. The User agrees that S Mobility may provide details of User’s use of the service to regulators or police or to any other third party, or in order to resolve disputes or complaints which relate to the service, at S Mobility’s complete discretion.


8. SERVICES

8.1 The Electronic Media is a technological platform that enables and permits the Users to arrange, obtain and avail the transportation services offered by the Third-Party Providers through the Electronic Media. It has been agreed between the parties that upon booking of Services through the Electronic Media, the arrangement constitutes a separate and bilateral association between the User and the Third-Party Providers. It is hereby clarified that for the purpose of facilitation of interaction, communication and transaction between the Third Party Provider and the User is an independent and separate transaction between such Third Party Provider and User and Electronic Media is only acting as an intermediary service provider and/or facilitator for the purpose of facilitation of interaction, communication and transaction between the Third Party Provider and the User; and hence, S Mobility shall not be liable and responsible for any and all liabilities, losses, claims and damages that may arise out of or in connection with the interaction, communication and transaction between the Third Party Provider and the User and the User agrees to hold harmless and indemnify S Mobility in this regard.

8.2 You hereby acknowledge and agree that Third Party Providers are not the employees, agents, representatives, contractors, advisers, staff members of S Mobility and S Mobility has no responsibility for any act, omission, advice, service, representation, etc. of any such Third Party Provider. As further described in this Terms of Use,S Mobility makes no representation. warranty or guarantee whatsoever as to (a) the availability of the Third Party Providers; (b) the accuracy of the representations made or the information posted on the Electronic Media by the Third Party Provider; (c) the accuracy of the information exchanged between the Third Party Provider and the accuracy of the information posted on the Electronic Media by the Third Party Provider and the User; (d) the quality, nature, usefulness, relevance, accuracy of the services provided by the Third Party Providers.

8.3 The Service allows You to send a request through S Mobility Electronic Media to a Driver/Third Party Provider on the S Mobility network. The Driver has the discretion to accept or reject each request for the Service. If the Driver accepts a request, S Mobility notifies the User and provides information regarding the Driver - including Driver’s name, Vehicle license number, telephone contact details of the Driver and such other details as S Mobility may determine.

8.4 S Mobility shall procure reasonable efforts to bring the User into contact with a Driver, subject to the availability of a Driver in or around User’s location at the moment of User’s request for such Services.

8.5 By using the Application or the Service, the User further agrees that:

8.5.1 The User will only use the Service or download the Application for his/her sole, personal use and will not for commercial purposes resell or assign it to a third-party;

8.5.2 The User will not use an account that is subject to any rights of a person other than the User without appropriate authorization;

8.5.3 The User will not use the Service or the Electronic Media for unlawful purposes or carry any materials equipment or material that is prohibited under the Applicable Law or the use of which can be used for any illegal purposes/activities;

8.5.4 The User will not try to harm the Driver, Electronic Media or S Mobility’s network in any way whatsoever or cause any damage to the Vehicle;

8.5.5 The User will provide S Mobility with such information and documents which S Mobility may reasonably request;

8.5.6 The User will only use an authorized network to which it has access to for the purpose of availing service;

8.5.7 The User is aware that when requesting Services, whether by message, via Electronic Media or calling the call centre of S Mobility, standard messaging charges, data charges, voice charges, as applicable, for the User and/or User’s phone network service providers, will apply;

8.5.8 The User will comply with all Applicable Law from the User’s country of domicile and residence and the country, state and/or city in which the User is present while using the Electronic Media or Service;

8.5.9 The User is aware of and shall comply with the Information Technology Act, 2000 and the rules, regulations and guidelines notified thereunder.

8.5.10 The User will not, in his/her use of the Services, cause nuisance, annoyance, inconvenience, or property damage, to the Vehicle, Third-Party Provider or any other party. In certain instances, the User may be asked to provide proof of identity to access or use the Services, and the User agrees that the User may be denied access to or use of the Services if you refuse to provide proof of identity.

8.6 S Mobility reserves the right to immediately terminate the Service and the use of the Electronic Media in the event of non-compliance with any of the above requirements by the User.

8.7 The User is strictly prohibited from doing the following acts including without limitation:
Smoking and/or drinking in the Vehicle, misbehaving with Driver, discriminating with Driver in any form (including without limitation discrimination based on Driver’s caste, creed, sex, gender, religion, race, place of birth and language), harass, slap, hurt, torture, extort, or assaulting the Driver in any manner, tear off any advertisement(s) material or promotional material placed in the Vehicle, disturb or distract the Driver while driving, make unnecessary noises, leave without paying, jump out of the Vehicle, abuse the Driver in any manner, doing acts which may harm the interiors of the Vehicle, causing fire in the Vehicle or doing any act in violation of the Applicable Laws.

8.8 In the event the User is found to be involved in the activities as set out above, the User shall be liable to pay a fine to S Mobility and S Mobility shall also have the right to terminate the Ride and/or User’s Account, immediately.

8.9 In the event User fails to pay fine after the completion of the Ride, we may at our discretion, take such steps as may be available to us under Applicable Law for recovery of the fine or any amount due and payable by User on account of loss, damages, costs and expenses that are incurred by S Mobility and/or Third Party Providers arising out of/or in relation to the illegal or unauthorised use of services or any act and omission on the part of the User in complying the terms herein. The User shall also be blacklisted as a result of non-payment of the fine or misbehaving or causing the aforementioned acts, as the case may be, and in such event, User’s Account may be terminated by S Mobility.


9. CONFIRMATION OF BOOKING

9.1 S Mobility, upon receipt of a booking for the Service, uses various algorithms to appoint a Driver closest to the requested location of the User. Upon assigning the Driver towards the request of the User or the Service booking request, the Driver has the discretion to accept or decline the booking made by the User through the Electronic Media or through phone call.

9.2 S Mobility shall, upon receiving the booking request from the User and confirmation from the Driver in the manner set out above, proceed to communicate the confirmation or non-acceptance of the booking based on the availability of the Vehicle at the pickup time, which shall be informed to the User vide an SMS or email or notification on the Application.

9.3 The User shall bear the consequences and damages for any delay that may be caused to the User due to the User’s failure to provide the correct pick up details and time or to check the confirmation SMS or email or failure to inform S Mobility of the incorrect details immediately.


10. PAYMENT

10.1 The User will be charged the Total Ride Fee by S Mobility in respect of each Ride towards the transportation services and the Services availed by the User through the Electronic Media The User expressly acknowledges that the Total Ride Fee indicated on the platform at the time of booking is indicative only and may vary at the time of payment and ending of the Ride based on the actual trip undertaken (i.e exact kilometres travelled, time taken to arrive at the location, change of location during the trip etc.) or such fee may change due to any exigencies in certain specific geographical areas (like curfews, road closures etc.), or due to the User opting for additional services; and the User will be liable to pay the actual Total Ride Fee as calculated and appearing on the Electronic Media at the end of each trip/Ride or as may be notified to the User through S Mobility’s call centres if the booking is made through a call. S Mobility will use reasonable efforts to inform you of the charges that may apply, provided that you will be responsible for any such charges incurred under your Account regardless of your awareness of such charges or the amounts thereof.

10.2 The User understands that it will be charged with a Location Fee and fees attributable to any toll duty, inter-state taxes, airport charges or any other charge/fee imposed by the governmental instrumentalities or any third party with regard to operation of the Vehicle at specific locations. The User acknowledges that the Additional Fee may be revised at the sole and absolute discretion of S Mobility and such revised fee will be notified on the Electronic Media from time to time on the mode of booking by the User from time to time.The User acknowledges and agrees that as against the fee payable during Relaxed Hours, a differential Surge Charges shall be payable for Ride(s) conducted during Rush Hour(s) on account of the Driver(s) requiring greater time and effort for conducting the Ride(s) on account of increased traffic and taking into account market dynamics impacting demand and supply during the Surge Hours.

10.3 Further, the User shall be required to pay Cancellation Fee in case of a cancellation of the Ride in accordance with the terms of Clause 11 (Cancellation Policy).

10.4 Upon the User opting for payment by cash option at the time of the booking, the Total Ride Fee will be collected by the Driver from the User at the end of the Ride.

10.5 S Mobility shall provide a receipt and acknowledgement in respect of the Ride for the Total Ride Fee payable by the User at the end of the Ride and the User may raise a request for a copy of the invoices from the support page on the Electronic Media.

10.6 S Mobility or Third-Party Providers reserve the right to charge you any reasonable costs required to clean or repair the Vehicle attributable to you during a Ride and S Mobility will collect payment for such costs as the agent of the Third Party Providers. You shall be responsible for such cost of repair for damage to, or necessary cleaning of, the Vehicles and property resulting from use of the Services under your Account in excess of normal “wear and tear” damages and necessary cleaning (“Repair or Cleaning”). In the event that a Third Party Provider reports the need for Repair or Cleaning, and such Repair or Cleaning request is verified by S Mobility in S Mobility’s reasonable discretion, S Mobility reserves the right to facilitate payment for the reasonable cost of such Repair or Cleaning to be paid by the User using the payment method designated in the User’s Account.

10.7 This payment structure is intended to fully compensate the Third-Party Provider for the Services or goods provided. S Mobility does not designate any portion of your payment as a tip or gratuity to the Third-Party Provider. Any representation by S Mobility (on the Website, in the Application, or in S Mobility’s marketing materials) to the effect that tipping is “voluntary,” “not required,” and/or “included” in the payments you make for services or goods provided is not intended to suggest that S Mobility provides any additional amounts, beyond those described above, to the Third Party Provider. You understand and agree that, while you are free to provide additional payment as a gratuity to any Third Party Provider who provides you with services or goods obtained through the Service, you are under no obligation to do so. Gratuities are voluntary. After you have received Services or goods obtained through the Service, you will have the opportunity to rate your experience and leave additional feedback about your Third-Party Provider.

10.8 The User shall choose to pay for the Total Ride Fee (whether in whole or in part) by either of the following four methods:

10.8.1 Cash payment: Cash payment towards the Total Ride Fee after the completion of Ride can be made to the Driver. In addition, the Location Fee and/or the Additional Fee may also be paid by the User in cash, in which event the same will not be calculated while charging the Total Ride Fee.

10.8.2 E-Wallet payment: S Mobility offers Users the facility of making an online payment through an E-Wallet as per clause 3.9, powered by a third-party payment processor ("Payment Processor"). The processing of payments, in connection with Users use of the E-Wallet will be subject to the terms, conditions, and privacy policies of the Payment Processor that S Mobility engages for this purpose. S Mobility will not be responsible for any errors by the Payment Processor in any manner. The money added to S Mobility Wallet is strictly non-refundable and holds no expiry date.

10.8.3 Credit Card/ Debit Card/ Net Banking Payment: The User shall have the facility of making a payment online for the Total Ride Fee as maybe charged by S Mobility for the Ride undertaken by the User. The Total Ride Fee in this regard may be paid by using the services of an entity providing a payment gateway/processor services ("Payment Gateway"), authorized by S Mobility. Such Payment Gateway may either be S Mobility or any of its affiliates or partners or unrelated third parties. The User hereby agrees and undertakes to share relevant payment details including their credit/debit card details (“Card Details”) with the Payment Gateway for the successful completion of payment towards the Total Ride Fee to S Mobility Application and authorize the Payment Gateway to complete such transaction(s). In this respect, it is clarified that all Payment Gateways whose services are utilized for the purposes of the Website and/or Application and/or services shall be PCI-DSS (Payment Card Industry – Data Security Standard) compliant. The User’s authorization permits the Payment Gateway to debit or credit the bank account associated with the User’s payment details. User’s authorization further permits the Payment Gateway to use User’s Card Details for the processing of transactions initiated by the User at any of S Mobility’s affiliates. The User shall have the option to save the Card details or bank details for ease of payment in the next trip or Ride through S Mobility as User’s authorization, as long as the User maintains an Account with S Mobility on their Application. In the event the User deletes his/her Card Details with the Payment Gateway or if the User deletes the Account, the Payment Gateway will not process any further transactions initiated by the User at the Website or Application and at the sites of any of S Mobility’s affiliates. User’s authorization under this clause is subject to any other terms and conditions of the Payment Gateway.

10.9 Any payment related issue, except when such issue is due to an error or fault in the Application and/or Website, shall be resolved between the User and the Payment Processor/Payment Gateway. S Mobility shall not be responsible for any unauthorized use of the User’s E-Wallet/ Credit Card/ Debit Card/ Net Banking Payment during or after availing the Services on the Application and/or Website.

10.10 If S Mobility has a reason to believe that any payment instructions made on the User’s Account have been fraudulently made, S Mobility will suspend and deny the User any access to his/her Account in order to investigate and report the suspicious activity. Such suspension will continue in effect till the conclusion of the investigation.


11. CANCELLATION AND WAITING FEE POLICY

11.1 The User hereby agrees and acknowledges that the User may cancel the request for a Vehicle from a Driver at any point of time subject to a Cancellation Fee charged by S Mobility, in accordance with S Mobility’s Cancellation & Waiting Policy, in the manner as described below:

11.1.1 For the purpose of Vehicles requested and/or scheduled by the Users through the Application or on the call directly through the operator’s at S Mobility, the Cancellation Fee will be charged in the following manner:

(a) If the User cancels after the Vehicle is allotted; or

(b) If the Driver assigned to the User, waits for more than a pre-decided time at the User’s location after the scheduled slot.
Notwithstanding the foregoing, if the Driver has delayed reaching the User’s location by more than even 1 (One) minute to pick the User, the User will not be charged any Cancellation Fee for such Ride.

11.2 The User shall be notified regarding the applicable Cancellation Fee in advance whenever the User attempts to cancel a booking/service request with S Mobility. The notification regarding the same shall be on the Application and/or the Website.

11.3 S Mobility shall provide a receipt of the Cancellation Fee, if any, payable by the User for every cancellation in terms of the clauses above for such cancellations. The User may raise a request for a copy of the invoices regarding such cancellation from the support page on the Electronic Media.

11.4 The Cancellation Fee shall be due and payable by the User at the time of cancellation which may be recovered from the User immediately at the time of Cancellation or at the completion of the User’s subsequent Ride.

11.5 A nominal Waiting Fee shall get added to the fare of the ride in case the driver waits at the rider’s pickup location and/or intermediate stops for more than a pre-decided time, in accordance with S Mobility’s Cancellation and Waiting Policy.

11.6 S Mobility shall not cancel any ride unless explicitly requested by the rider via S Mobility app, email or phone. This is valid for all situations unless arising out of adverse weather, traffic, law and order or any other condition as deemed appropriate by S Mobility.


12. REFUNDS

12.1 In the even the User is of the view that he/she has been over charged in respect of any particular Ride by the Driver, then he/she may raise a refund request on the Electronic Media. Where S Mobility determines that a refund request is valid, it shall make reasonable efforts to grant the refund request and return the requisite funds to the respective source of payment. In the event that the User desires a refund on any amount that has been debited from the User’s account either through third party wallet or S Mobility Wallet or through the saved credit card/debit card/ net banking and the same was not authorised by the User, the User shall be required to email saurabh.chandra@s-mobility.in or contact S Mobility on +91-8007005005 and make a refund request clearly explaining the circumstances of the User’s refund request.

12.2 In case User has erroneously paid an amount in excess of the applicable Total Ride Fee through the payment methods made available on the platform, which was not required to be transferred, after assessing the validity of the written request from the User, S Mobility shall process to credit the funds to the same source from where these were received.

12.3 S Mobility will make reasonable efforts to respond to the User’s refund request at the earliest. Please note that S Mobility will not be responsible for delays, which may be caused by any third parties such as banks, Payment Processors, Payment Gateways and third party -merchants, on whom S Mobility relies while processing refund request or any delay caused in case of any force majeure events which are beyond S Mobility’s reasonable control. Therefore, S Mobility shall bear no liability for the processing of the refund requests.


13. DISCLAIMERS, WAIVER AND RELEASE

13.1 Except for S Mobility’s limited role in processing payments that User’s authorize or initiate, S Mobility is not involved in any underlying transaction between the User, Third Party Provider, any other customer, merchant, bank, Payment processor, Payment Gateway or other third party.

13.2 S Mobility shall not be liable for the quality, safety, reliability, legality, delivery or any other aspect of any goods or service that User may purchase through any Third-Party Advertisers. The User’s use of the service is at his/her sole risk.

13.3 The service is provided on an "as is" and "as available" basis. S Mobility disclaims all warranties of any kind whether express or implied including without limitation, any representation or warranty for accuracy, availability, continuity, uninterrupted access, timeliness, sequence, quality, performance, security, merchantability, fitness for any particular purpose, non-infringement or completeness. Without prejudice to the forgoing paragraph, S Mobility does not warrant that:

13.3.1 This Website and Application will be constantly available, or available at all; or

13.3.2 This Website: information, content, materials, product (including software) or Services included on or otherwise made available to the User through the Website; their servers; or electronic communication sent from S Mobility are free of viruses or other harmful components; and

13.3.3 Nothing on the Website will constitutes, or is meant to constitute, advice of any kind.

13.4 S Mobility makes no express or implied representations or warranties about it’s Services or the Website and Application and disclaim any implied warranties, including, but not limited to, warranties or implied warranties of merchantability or fitness for a particular purpose or use or non-infringement. S Mobility do not authorize anyone to make a warranty on S Mobility’s behalf and User may not rely on any statement of warranty as a warranty by S Mobility.

13.5 S Mobility and its representatives, officers, employees, agents and contractors shall not be liable for any loss, damage, claim, expense, cost (including legal costs) or liability arising directly or indirectly from the User’s use or non-use of the Service or the Electronic Media, or User’s reliance upon the Service or the information contained upon the Electronic Media (whether arising from S Mobility or any other person's negligence or otherwise).

13.6 The User furthermore acknowledges and accepts that S Mobility may not encrypt any content or communications from and to our Electronic Media.

13.7 THE EXCHANGE OF INFORMATION, PROVISION/AVAILING OF SERVICES, TRANSACTION BY AND BETWEEN THE THIRD PARTY PROVIDER AND USER IS DONE AT YOUR SOLE RISK, AND HENCE YOU AGREE THAT THE COMPANY SHALL BE IN NO EVENT LIABLE FOR ANY INJURY, LOSSES, CLAIM, DAMAGE OR ANY SPECIAL, EXEMPLARY, PUNITIVE, INCIDENTAL OR CONSEQUENTIAL DAMAGES OF ANY KIND, WHETHER BASED IN CONTRACT, TORT OR OTHERWISE, WHICH ARISES OUT OF OR IS ANY WAY CONNECTED WITH THE AFORESAID.

13.8 THE USER SHALL NOT HAVE ANY PLEA, CLAIM OR DEMAND AGAINST THE COMPANY, ITS AFFILIATES, AND THEIR RESPECTIVE OFFICERS, DIRECTORS, SHAREHOLDERS, EMPLOYEES, SUB-CONTRACTORS AND AGENTS ETC. IN RESPECT OF ANY EXCHANGE OF INFORMATION, PROVISION/AVAILING OF SERVICES, TRANSACTION BY AND BETWEEN THE THIRD-PARTY PROVIDER AND USER. THE COMPANY WILL NOT BE DEEMED THE PROVIDER OR RECIPIENT OF ANY SERVICES ACQUIRED THROUGH BY A USER THROUGH THE ELECTRONIC MEDIA OR PURSUANT TO REGISTRATION OF A PERSON AS A USER WITH THE COMPANY AND THE ELECTRONIC MEDIA.

13.9 IN THE EVENT OF A DISPUTE BETWEEN THE THIRD PARTY PROVIDER AND THE USER REGARDING ANY TRANSACTION CONDUCTED VIA THE ELECTRONIC MEDIA, BOTH THE USERS HEREBY RELIEVES THE COMPANY, ITS AFFILIATES, THEIR RESPECTIVE OFFICERS, DIRECTORS, SHAREHOLDERS, EMPLOYEES, SUB-CONTRACTORS AND AGENTS FROM ALL MANNER OF ACTIONS, CLAIMS OR DEMANDS AND FROM ANY AND ALL LOSSES (DIRECT, INDIRECT, INCIDENTAL OR CONSEQUENTIAL), DAMAGES, COSTS OR EXPENSES, INCLUDING, WITHOUT LIMITATION, COURT COSTS AND ATTORNEYS' FEES, WHICH A USER MAY HAVE AGAINST THE OTHER.

13.10 THE USERS EXPRESSLY UNDERSTANDS AND AGREES THAT THE COMPANY DISCLAIMS ANY LIABILITY WITH RESPECT TO ANY CLAIM, SUIT OR ACTION BROUGHT BY THE USER OR ANY THIRD PARTY IN RELATION TO ANY USE OF/AVAILING OF THE SERVICES OFFERED/PROVIDED BY THE THIRD PARTY PROVIDER; AND THE THIRD PARTY PROVIDER AGREES TO INDEMNIFY, DEFEND AND HOLD THE COMPANY HARMLESS IN CONNECTION WITH ANY SUCH CLAIM.

13.11 THE USERS EXPRESSLY UNDERSTANDS AND AGREES THAT THE COMPANY DISCLAIMS ANY LIABILITY WITH RESPECT TO ANY CLAIM, SUIT OR ACTION BROUGHT BY A THIRD PARTY PROVIDER IN CONNECTION WITH PAYMENT FOR SERVICES BY THE USER AND THE USER AGREES TO INDEMNIFY, DEFEND AND HOLD THE COMPANY HARMLESS IN CONNECTION WITH ANY SUCH CLAIM.

13.12 THE AFORESAID, DISCLAIMERS, WAIVERS, RELEASES, LIMITATIONS AND EXCLUSIONS WILL APPLY REGARDLESS OF WHETHER THE CAUSE OF ACTION OR THE LIABILITY ARISES IN LAWS, CONTRACT, TORT OR OTHERWISE.


14. ADVERTISMENT AND LINKS

14.1 S Mobility may facilitate and allow third party advertisers (“Third- Party Advertisers”) to place advertisements on the Electronic Media or through physical presence by placing tags, stickers, pamphlets, articles, promotional offers etc. as may be placed in the Vehicle(s). S Mobility has guidelines and policies to be followed by such Third-Party Advertisers for placing such advertisements (the “Advertising Policy”).

14.2 S Mobility distinguishes between the content posted by it and the content that is created or provided by one of the Third-Party Advertisers. The advertisements will be labelled as from S Mobility’s advertisers, or advertisement. The content posted is not reviewed by S Mobility, but it shall be subject to the Advertising Policy.

14.3 Please note that We do not verify any content or information provided by the Third Party Advertisers on the Electronic Media and to the fullest extent permitted by Applicable Law(s), disclaim all liability arising out of Third Party Advertisers’ use or reliance upon the Electronic Media or the Vehicle, availing the Services, content posted by Third Party Advertisers, representations and warranties made by the Third Party Advertisers on the Electronic Media or towards the Vehicle or any loss arising out of the manner in which the Services have been availed by the Users.

14.4 The Electronic Media may be linked to the website of third parties, affiliates and business partners. By clicking on the advertisements by Third Party Advertisers, the User may be redirected to a website or other electronic platform of any Third-Party Advertisers or receive other messages, information or offers from such Third-Party Advertisers. We have no control over, and not liable or responsible for content, accuracy, validity, reliability, quality of such websites or made available by/through the Electronic Media. Inclusion of any link on the Electronic Media does not imply that we endorse the linked website. The User is wholly liable for all communications and transactions with such Third-Party Advertisers. The User acknowledges and agrees that S Mobility is not liable or responsible for the content, products or services of such advertisers or the websites, links, information, messages, offers or privacy practices of such Third-Party Advertisers.

14.5 The User understands that advertising plays an important role in the provision of this Service, and that we will display advertisements and other information adjacent to or as part of the Services, which the User may use. S Mobility may periodically send promotional emails, SMSs or push notifications to the User about Services offered by it’s advertisers and ourselves.

14.6 Wherever S Mobility publishes or provides content or advertisements as part of a Service we do not: warrant or represent that the content or advertisements are suitable, accurate, correct, complete, reliable, appropriate, or lawful; or endorse the content or advertisements; and the User will obtain independent professional advice at User’s own cost before taking any action based upon such content or advertisements.

14.7 User’s correspondence or dealings with, or participation in promotions of Third Party Advertisers found on or through the Electronic Media and/or Vehicle, including payment and delivery of related goods or services, and any other terms, conditions, warranties or representations associated with such dealings, are solely between the User and such Third Party Advertiser. User agrees that S Mobility shall not be liable for any loss or damage of any nature incurred as the result of any such interactions, or as the result of the presence of such Third-Party Advertisers on S Mobility’s Electronic Media and/or Vehicle.

14.8 In many cases, S Mobility provides services of third parties, or it’s Services in conjunction with that of third parties. In those cases, the following conditions apply: S Mobility provides such services subject to the terms, conditions and limitations imposed by those third parties. If those third parties change, suspend or stop providing such services, S Mobility may similarly change, suspend or stop providing the Services to the User without notice. S Mobility may nevertheless endeavor to provide such a service in another way or by using another third party. User’s authorize S Mobility to provide any of User’s Personal Information (as defined in S Mobility’s Privacy Policy) to those third parties to the extent that it may be necessary to enable the third parties and ourselves to provide the services to the User. To the extent that there is a conflict between the third party's Terms and Conditions and these Terms of Use, these Terms of Use will prevail.

14.9 When the User acquires goods, softwares or any other services from a third party through any of S Mobility’s Electronic Media, User understands and agrees that: S Mobility is not a party to the contract between User and the third party; S Mobility is under no obligation to monitor the third party service used by the User; the third party will be responsible for all obligations under the contract including (without limitation) warranties or guarantees; User will evaluate the product, software or service and the applicable terms and conditions before acquiring the product, software or service; and User will indemnify S Mobility against any damages, howsoever arising from your acquisition and use of the goods, software or service.

14.10 S Mobility also reserves the right to reject or refuse any third-party service used by the User in conjunction with S Mobility’s Electronic Media.


15. CUSTOMER RELATIONSHIP MANAGEMENT

15.1 All issues, opinions, suggestions, questions and feedback while availing S Mobility’s Services shall be communicated to us through the following email address saurabh.chandra@s-mobility.in . In case of a Ride booked on the Application, User shall be required to rate the Driver after completion of the Ride. User hereby agrees to be fair, accurate and non-disparaging while leaving comments, feedbacks, testimonials or reviews on or about the Rides or Services.

15.2 Reporting of any issue needs to be within 7 (seven) days of the happening of the issue, failing which, such issue will not be addressed. The User agrees and acknowledges that any and all issues/complaints/grievance a User has, must be communicated to S Mobility only through the Electronic Media or to consumer helpdesk or grievance offices as stipulated in these Terms of Use of the Electronic Media and not on any other third party platform/ social media channels.

15.3 The User shall be responsible and liable in respect of the wrongful allegation made and any issues posted on such other third-party platforms/social media. S Mobility takes no liability for inability to get back or respond to any complaints made or content posted by the User about the Driver or S Mobility on other channels.

15.4 S Mobility shall endeavor to respond to User’s issues within 2 (two) working days of User’s reporting the same and endeavor to resolve it at the earliest possible. It is hereby clarified that issues are resolved on severity basis, and certain may be resolved earlier than the other. However, S Mobility shall not be liable for any damages or losses in the event the User is not satisfied with any such resolution.


16. INDEMNIFICATION
In addition and not in derogation of the specific indemnities provided by you to S Mobility under these Terms of Use and/or S Mobility Policies, you agree to indemnify, defend and hold harmless the Company, S Mobility and its affiliates, officers, directors, employees, consultants, representatives, shareholders, contractors, users and agents etc. (“Indemnified Parties”) against any/all losses, liabilities, claims, damages, demands, costs and expenses (including legal fees and disbursements in connection therewith and interest chargeable thereon) arising out of or in connection with any claim, suit, action, or other proceeding brought against Indemnified Parties, to the extent such Losses are based on or arising out of or in connection with: (a) any breach or non-performance or non-compliance of any of the Terms of Use and/ or other terms and conditions of Platform with respect to use and access of the Platform and/or availing Services on/through the Platform and/ or purchase of the product or services of Third Party Advertisers; (b) for truthfulness and correctness of the information provided by the User at the time of registration and/ or availing the Services; (c) any claim which the Driver/Third Party Providers may have with respect to interaction, communication, dealing, dispute, and transaction between the Driver/Third Party providers and the User; (d) any content posted by the User on the Platform and User's use of the Services available on or through the Platform; (e) any claim of third party due to, or arising out of, or in connection with, your use of the Platform and/ or availing of Services by the User; (f) breach of any third party rights (including, but not limited to, claims in respect of defamation, invasion of privacy, or infringement of any other intellectual property right) by you.


17. LIABILITY

17.1 The information, recommendations and/or Services provided to the User on or through the Electronic Media, the Application and S Mobility’s call center are for general information purposes only and does not constitute advice. S Mobility will reasonably keep the Electronic Media and its contents correct and up to date but does not guarantee that (the contents of) the Electronic Media is free of errors, defects, malware and viruses or that the same is correct, up to date and accurate.

17.2 S Mobility shall not be liable for User missing trains/flights/events or delays etc as the Service is dependent on many factors not in S Mobility’s control. User must book the Ride after taking into account the check-in time, traffic and weather conditions, political rallies, natural calamities, traffic barricades, car breakdowns and other un-expected delays.

.3 In the event, there is a delay by the Vehicle in reaching the pickup location beyond 10 (Ten) minutes of the pickup time, S Mobility shall only endeavour to get the User in touch with the Driver assigned for the Ride.

17.4 S Mobility shall not be liable for any damages resulting from the use of or inability to use the Electronic Media, including damages caused by wrong usage of the Electronic Media, error in call centre number, network issues, malware, viruses or any incorrectness or incompleteness of the information or the or Application.

17.5 The user shall take full responsibility of his/her items and luggage. In case of lost items inside the vehicle during the journey, S Mobility will try to locate the items on a "best-effort" basis but is not responsible for the same in case of loss or damage to the same. If the user leaves any goods in the vehicle or have any complaint in respect of the services or the use of the vehicle, user shall have to inform S Mobility of the same within 24 hours of using the vehicle or the services of S Mobility. If the items are found in the cab, Our team will communicate the further steps on how to retrieve the lost item.

17.6 S Mobility does not assure a complete sustainability of its Service and shall not be held responsible or liable for the same, in any manner.

17.7 S Mobility shall not be responsible for any loss of communication / information of status update and benefits under the program. All this information will be sent on mobile number and/or email ID registered with S Mobility. S Mobility will not be responsible for appropriateness of mobile or email or any other communication medium. The User shall be responsible for immediately reporting the errors, if any, occurred in the information sent to the User regarding booking confirmation.

17.8 IN NO EVENT SHALL S Mobility BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES OR FOR ANY DAMAGES WHATSOEVER, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF S Mobility HAS BEEN ADVISED OF THE POSSIBILITY THEREOF.

17.9 IN ADDITION, AND WITHOUT LIMITING THE FOREGOING, TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL S Mobility’s AGGREGATE LIABILITY ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OF USE OR THE SERVICES RENDERED HEREUNDER, WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE, PRODUCT LIABILITY, OR OTHER THEORY), WARRANTY, OR OTHERWISE, EXCEED THE AMOUNT OF Rs. 1000/- (Rupees One Thousand only).

17.10 If Applicable Law does not permit the exclusion of certain warranties or the limitation or exclusion of liability, the scope and duration of such warranty exclusions and the extent of the liability of S Mobility shall be the minimum permitted under Applicable Law.


18. INTELLECTUAL PROPERTY OWNERSHIP

18.1 S Mobility alone (and its licensors, where applicable) shall own all right, title and interest, including all related intellectual property rights, in and to the Electronic Media and the Service and any suggestions, ideas, enhancement requests, feedback, recommendations; text, graphics, user interfaces, visual interfaces, photographs, trademarks, logos, sounds, music, artwork and computer code; or other information provided by the User or any other party relating to the Electronic Media or the Service.

18.2 Third party trademarks may appear on this Electronic Media and all rights therein are reserved to the registered owners of those trademarks. For use of any third party's intellectual property, User needs to get permission directly from the owner of the intellectual property for any use.

18.3 These Terms of Use do not constitute a sale and do not convey to the User any rights of ownership in or related to the Electronic Media or the Service, or any intellectual property rights owned by S Mobility. The User shall be solely responsible for any violations of any laws and for any infringements of any intellectual property rights caused by use of the Services or the Electronic Media.

18.4 The User may use information on the Electronic Media purposely made available by S Mobility for downloading from the Electronic Media, provided that the User:

18.4.1 does not remove any proprietary notice language in all copies of such documents and make no modifications to the information;

18.4.2 use such information only for User’s personal, non-commercial informational purpose and do not copy or post such information on any networked computer or broadcast it in any media; or

18.4.3 does not make any additional representations or warranties relating to such information.


19. SUSPENSION AND TERMINATION

19.1 You agree that S Mobility, in its sole discretion and for any or no reason, may terminate the account (or any part thereof) you may have with S Mobility or use of the Electronic Media and remove and discard all or any part of your account or any content uploaded by you, at any time. S Mobility may also in its sole discretion and at any time discontinue providing access to the Electronic Media, or any part thereof, with or without notice. You agree that any termination of your access to the Electronica Media or any account you may have or portion thereof, may be effected without prior notice, and you agree that S Mobility will not be liable to you or any third party for any such termination.

19.2 In addition to the reasons and the grounds of termination expressly provided for in these Terms of Use above, the Company, in its sole discretion, reserves the right to terminate any account (or any part thereof) or deny access to any account or use of the Service or remove and discard any content within the Service, for any reason, including, without limitation:

19.2.1 registration of any account using false or misleading information; or

19.2.2 violation or inconsistent acts with the letter or spirit of the Terms of Use; or

19.2.3 violation of any Applicable Laws.
ß
19.3 You acknowledge and agree that S Mobility may immediately deactivate or delete your account and all related information and files in your account and/or bar any further access to such files or the Service. Further, you agree that S Mobility shall not be liable to you or any third-party for any termination of your account or denial of access to the Service. In the event of termination of your account by S Mobility due to any of the aforementioned reasons, S Mobility shall have the sole discretion to terminate or cancel any of your past acts without any liability to S Mobility.

19.4 S Mobility does not permit copyright infringing activities on the Electronic Media and reserves the right to terminate access to the Electronic Media and remove all content submitted by any persons who are found to be infringers. Any suspected fraudulent, abusive, or illegal activity that may be grounds for termination of your use of the Electronic Media may be referred to appropriate law enforcement authorities. These remedies are in addition to any other remedies S Mobility may have under law, contract and/or equity.

19.5 You may, by giving a written notice to us at the following link saurabh.chandra@s-mobility.in , terminate or deactivate your account at any time and/or remove any data or files uploaded by you on the account or request us to do the same. Provided that such deactivation or termination either by you or by us will not prejudice any of your past lawful acts on the Electronic Media.

19.6 Unless terminated in accordance with this Clause, the agreement between S Mobility and the User is perpetual in nature upon downloading the Application and for each Ride booked through the Website or Application or through call center.

19.7 The User is entitled to terminate the agreement at all times by deletion of the User Account, thus disabling the use by the User of the Electronic Media. The User can close the User Account at any time by following the instructions on the Electronic Media.

19.8 Termination of this agreement will not prejudice accrued rights of either S Mobility or User.


20. APPLICABLE LAW AND DISPUTE RESOLUTION

20.1 These Terms of Use are subject to the laws of India.

20.2 All disputes arising out of or in connection with the Terms of Use shall be attempted to be settled through negotiation between senior management of S Mobility and the User. If any dispute arising between the parties is not amicably settled within reasonable period of sixty (60) days of the initiation of the aforesaid dispute resolution mechanisms, then, the same shall be resolved by arbitration in accordance with the provisions of the Rules of Arbitration of the Indian Council of Arbitration for the time being in force, which rules shall be deemed to be incorporated by reference in this Clause 20. The dispute shall be referred to one (1) arbitrator to be appointed/ nominated by the Company. The place of arbitration shall be New Delhi. The language of the arbitration will be English. The decision of the arbitrator will be final, binding and incontestable and may be used as a basis for judgment thereon in India or elsewhere. S Mobility and the User will bear its own costs of the arbitration.


21. GENERAL

21.1 We are not liable for any infringement of copyright, trademark, patterns and/or any intellectual or proprietary rights of any third party, arising out of contents and/or materials posted on or transmitted through the Electronic Media or items advertised thereon.

21.2 We shall have no liability to you for any failure and/or delay in performance of Services or any interruption or delay, to access the Electronic Media, if that failure and/or delay is due to reasons or circumstances beyond our reasonable control (and the time for performance of the same shall be and is extended accordingly). However, if we decide to grant you an indulgence on the performance of any obligation under these Terms of Use, such indulgence shall not constitute waiver of any of our rights.

21.3 These Terms of Use and S Mobility Policies incorporated herein by reference constitute the entire agreement between the parties with respect to the subject matter hereof and supersedes and replaces all prior or contemporaneous understandings or agreements, written or oral, regarding such subject matter.

21.4 The rights and obligations under the Terms of Use which by their nature should survive will remain in full effect after termination or expiration of the Terms of Use.

21.5 If any provision of the Terms of Use becomes or is declared by a court of competent jurisdiction to be invalid, illegal or unenforceable then such invalidity, illegality or unenforceability shall not affect the validity of the other provisions of the Terms of Use, which shall remain in full force and effect.

21.6 No representation of our employees, officers or agents or those contained and advertised on the Website or the Electronic Media shall represent an addition or amendment to these Terms of Use unless the same has been set out in writing and signed by one of our directors.

21.7 You agree not to assign, transfer or novate your rights or obligations under these Terms of Use, without our prior written consent, if applicable.

21.8 The laws of India will govern these Terms of Use. By accepting to these Terms of Use you agree to irrevocably submit to the exclusive jurisdiction of the courts at New Delhi in respect of any disputes, acts, matters or controversies arising as a result of breach of these Terms of Use those relating to the validity, enforcement and/or interpretation of the terms of these Terms of Use.

21.9 Failure on our part to exercise or enforce any right or provision of these Terms of Use shall not constitute a waiver of such right or provision.

21.10 No partnership, joint venture or relationship of employee/employer or franchisor/franchisee arises between you and us by reason of these Terms of Use.

21.11 In our discretion we may serve any notice or communication on you by email, fax or mail. In the case of notices sent by (a) fax, you will be deemed served at the time and date of dispatch and receipt of confirmation regarding successful transmission of the fax; (b) email, you will be deemed served at the time and date of receipt of such email by you; and (c) mail, you will be deemed served 5 (five) business days after dispatch of the same.

21.12 No person other than you has any rights under these Terms of Use and cannot enforce these Terms of Use.

21.13 As part of the registration process you agree to receive such marketing and promotional materials via mail, SMS, email and/or fax as we may deem appropriate to send you in connection with our Website and Services. For further details relating to the same, please refer to our Privacy Policy.

21.14 The invalidity of any term of these Terms of Use shall not affect the validity of the other provisions of these Terms of Use. If and to the extent that any provision of these Terms of Use is invalid, or is unacceptable in the given circumstances, a provision shall apply between the parties instead that is acceptable considering all the circumstances, taking into account the content and the purpose of these Terms of Use .

21.15 S Mobility may give notice by means of a general notice on the Service or Electronic Media, or by electronic mail to User’s email address or a message on User’s registered mobile number, or by written communication sent by regular mail to User’s address on record in S Mobility’s account information.

21.16 The User may contact S Mobility by electronic mail to the support team at the email address saurabh.chandra@s-mobility.in .

21.17 S Mobility shall not be liable for any failure to perform any obligations under these Terms of Use, if the performance is prevented, hindered or delayed by a Force Majeure Event and in such case its obligations shall be suspended for so long as the Force Majeure Event continues.


22. GRIEVANCE OFFICER

Any complaints, abuse or concerns with regards to content and or comment or breach of these Terms of Use shall be immediately informed to Chief Grievance Officer with the electronic signature or in writing at the following address to:

Email: saurabh.chandra@s-mobility.in

Designation : Chief Grievance Officer, SAURABHCHANDRA AUTOMOTIVE PRIVATE LIMITED

Corporate Address : D-1b/538 Ground Floor, Sangam Vihar, New Delhi - 110080, India (IN)


`;
// Content object
const content = {
  terms: termsAndConditionsContent,
};



export default function TermsAndConditionsPage() {
  return (
    <>
      <Helmet>
        <title>S MOBILITY TERMS AND CONDITIONS FOR USERS</title>
      </Helmet>
      <div className="flex w-full flex-col gap-7 bg-white-a700">
        <div className="mr-1.5 flex flex-col gap-1 md:mr-0">
          <Header />
          <div className="relative h-[294px] content-center lg:h-auto md:h-auto">
            {/* Background Overlay */}
            <Img
              src="images/img_overlay.png"
              alt="Overlay"
              className="h-[284px] w-full flex-1 object-cover"
            />

            {/* Terms Section */}
            <TermsSection />
          </div>
          <div className="flex items-center justify-center bg-[url(/public/images/img_content_section.png)] bg-cover bg-no-repeat py-[78px] lg:py-8 md:py-5 sm:py-4">
            <div className="container-xs flex justify-center px-14 lg:px-5 md:px-5">
              <div className="flex  flex-col gap-[5px] lg:w-full md:w-full">
                {/* Dynamic Content */}
                {content.terms.split("\n").map((line, index) => (
                  <Text
                    key={index}
                    size="textxl"
                    as="p"
                    className=" text-[22px] font-normal leading-[33px] !text-black-900 lg:text-[18px]"
                  >
                    {line}
                  </Text>
                ))}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
