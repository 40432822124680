import { Heading } from "../../components";
import CarProfile from "../../components/CarProfile";
import React from "react";

export default function HowWeMakeADifferenceSection() {
  return (
    <>
      {/* How we make a difference section */}
      <div className="mt-[110px] flex flex-col items-center">
        <div className="container-xs flex flex-col items-center gap-[50px] px-5">
          {/* Section Title */}
          <Heading
            as="h2"
            className="text-[36px] font-semibold text-black-900 lg:text-[32px] md:text-[28px] sm:text-[24px]"
          >
            How we make a difference?
          </Heading>

          {/* Feature Grid */}
          <div className="grid w-full max-w-[1024px] grid-cols-2 gap-y-[40px] gap-x-[20px] sm:grid-cols-1">
            {/* Feature 1 */}
            <CarProfile
              carImage="images/icon1.png"
              carDescription="Regular car washing intervals"
            />
            {/* Feature 2 */}
            <CarProfile
              carImage="images/icon2.png"
              carDescription="Inhouse service and repairs"
            />
            {/* Feature 3 */}
            <CarProfile
              carImage="images/icon3.png"
              carDescription="Drivers trusted on behavior and professionalism"
            />
            {/* Feature 4 */}
            <CarProfile
              carImage="images/icon4.png"
              carDescription="One-touch customer call and support"
            />
          </div>
        </div>
      </div>
    </>
  );
}
