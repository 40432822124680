import { Button, Text, Heading } from "../../components";
import React from "react";
import { Link } from "react-router-dom";


export default function HomeBannerSection() {
    const scrollToAppDownload = () => {
      const element = document.getElementById("app-download-section");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    };
  return (
    <>
      {/* Home Banner Section */}
      <div className="relative h-[400px] bg-[url(/public/images/home-banner_image.png)] bg-cover bg-no-repeat">
        {/* Background Image */}

        {/* Black Overlay */}

        {/* Content */}
        <div className="absolute inset-0 z-20 flex flex-col justify-between">
          {/* Top Section: Title & Subtitle */}
          <div className="mt-16 text-center">
            <Heading
              as="h1"
              className="!text-[54px] font-bold !text-gray-100 !leading-[1.2] !lg:text-[36px] !md:text-[28px] !sm:text-[24px]"
            >
              Prebook <span className="text-yellow-400">Zero</span> Hassle Cabs
            </Heading>
            <Text
              as="p"
              className="mt-4 !text-[18px] font-normal uppercase tracking-wide text-gray-300 lg:text-[16px] md:text-[14px] sm:mt-2"
            >
              ZERO CANCELLATION • CLEAN CABS • POLITE DRIVERS
            </Text>
          </div>

          {/* Bottom Section: B2B and B2C */}
          <div className="flex">
            <div className="flex flex-row w-full ">
              {/* B2B Section */}
              <Link
                to="/contactus"
                className="flex-1 flex flex-col items-center justify-center bg-yellow-400 py-10 px-5 cursor-pointer hover:shadow-lg transition-shadow"
              >
                <div className="  ">
                  <Heading
                    as="h2"
                    className="!text-[30px] !font-bold !text-black !lg:text-[20px] !md:text-[18px]"
                  >
                    B2B
                  </Heading>
                  <Text
                    as="p"
                    className=" !text-[16px] font-normal !text-black-900_02 lg:text-[14px]"
                  >
                    Collaborate with us
                  </Text>

                  <Divider />
                </div>
              </Link>

              <div
                onClick={scrollToAppDownload}
                className="flex-1 flex flex-col items-center justify-center py-10 px-5 bg-gray-100 cursor-pointer hover:shadow-lg transition-shadow"
              >
                {/* B2C Section */}
                <div className="">
                  <Heading
                    as="h2"
                    className="!text-[30px] !font-bold !text-black !lg:text-[20px] !md:text-[18px]"
                  >
                    B2C
                  </Heading>
                  <Text
                    as="p"
                    className=" !text-[16px] font-normal !text-black-900_02 lg:text-[14px]"
                  >
                    Download our app
                  </Text>

                  <Divider />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}


 function Divider() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: 4,
        width: 40,
        marginTop: 2,
      }}
    >
      {/* Left Line */}
      <div
        style={{
          flexGrow: 10,
          backgroundColor: "black",
          borderRadius: 2.5,
          width: 2,
          height: 4,
        }}
      ></div>

      {/* Text */}
      <div
        style={{
          flexGrow: 2,
          height: 4,
          backgroundColor: "black",
          borderRadius: 2.5,
          width: 5,
        }}
      ></div>

      {/* Right Line */}
      <div
        style={{
          flexGrow: 1,
          height: 4,
          backgroundColor: "black",
          borderRadius: 2.5,
          // width: 5,
        }}
      ></div>
    </div>
  );
}