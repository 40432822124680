import { Heading } from "../../components";
import UserProfile from "../../components/UserProfile";
import React from "react";

export default function AmenitiesSection() {
  return (
    <>
      {/* Amenities Section */}
      <div
        className="mt-[82px] bg-gray-50 py-10"
        style={{ backgroundColor: "#F0F0F0" }}
      >
        <div className="flex flex-col items-center">
          <div className="container-xs flex flex-col items-center gap-[50px] px-5">
            {/* Section Title */}
            <Heading
              as="h2"
              className="text-[36px] font-semibold text-black-900 lg:text-[32px] md:text-[28px] sm:text-[24px]"
            >
              Amenities
            </Heading>

            {/* Features Grid */}
            <div className="grid w-full max-w-[1024px] grid-cols-2 gap-y-[40px] gap-x-[20px] sm:grid-cols-1">
              {/* Feature 1 */}
              <UserProfile
                userImage="images/icon5.png"
                userInfoText="Infotainment"
              />
              {/* Feature 2 */}
              <UserProfile
                userImage="images/icon6.png"
                userInfoText="Wifi in Car"
              />
              {/* Feature 3 */}
              <UserProfile
                userImage="images/icon7.png"
                userInfoText="220V Power Supply"
              />
              {/* Feature 4 */}
              <UserProfile
                userImage="images/icon8.png"
                userInfoText="Waterproof Luggage Carrier"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
