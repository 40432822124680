import { Helmet } from "react-helmet";
import { Text, Heading, Img } from "../../components";
import Footer from "../../components/Footer";
import ContactUsSection from "./ContactUsSection";
import React from "react";
import Header from "../../components/Header";

export default function ContactUsPage() {
  return (
    <>
      <Helmet>
        <title>Contact Support - Get in Touch with S Mobility</title>
        <meta
          name="description"
          content="Reach out to S Mobility for any inquiries or support. Use our provided contact details for quick assistance."
        />
      </Helmet>
      <div className="flex w-full flex-col gap-7 bg-white-a700">
        <div className="mr-1.5 flex flex-col gap-1 md:mr-0">
          <Header />
          <div className="relative h-[294px] content-center lg:h-auto md:h-auto">
            <Img
              src="images/img_overlay.png"
              alt="Overlay"
              className="h-[284px] w-full flex-1 object-cover"
            />

            {/* Contact Us Section */}
            <ContactUsSection />
          </div>
          <div className="flex items-center justify-center bg-[url(/public/images/img_content_section.png)] bg-cover bg-no-repeat py-[78px] lg:py-8 md:py-5 sm:py-4">
            <div className="container-xs flex justify-center px-14 lg:px-5 md:px-5">
              <div className="flex w-[62%] flex-col gap-[83px] lg:w-full md:w-full">
                {/* Contact Details */}
                <div className="flex flex-col items-start">
                  <Heading
                    size="headings"
                    as="h2"
                    className="text-[36px] font-semibold leading-[74px] !text-black-900 lg:text-[30px] md:text-[30px] sm:text-[28px]"
                  >
                    Get in Touch
                  </Heading>
                  <Text
                    size="text2xl"
                    as="p"
                    className="mt-4 text-[22px] font-normal leading-[33px] !text-black-900 lg:text-[18px]"
                  >
                    <>
                      Email: saurabh.chandra@s-mobility.in
                      <br />
                      Phone: +91 98996 82919
                      <br />
                      <>
                        SAURABHCHANDRA AUTOMOTIVE PRIVATE LIMITED
                        <br />
                        D-1b/538 Ground Floor, Sangam Vihar
                        <br />
                        New Delhi - 110080
                        <br />
                        India (IN)
                      </>
                    </>
                  </Text>
                  <div className="mt-8 h-px w-full bg-black-900" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
