import { Heading } from "../../components";
import UserReview from "../../components/UserReview";
import React, { Suspense } from "react";

const userReviewsList = [
  {
    userName: "Amit Sharma",
    reviewText: `"I frequently use S Mobility for outstation trips. The service is always reliable, the cars are clean, and the drivers are well-behaved. Highly recommended!"`,
  },
  {
    userName: "Pooja Verma",
    reviewText: `"S Mobility has made my daily office commute stress-free. The live tracking and punctual drivers ensure I am never late. A great app for working professionals!"`,
  },
  {
    userName: "Rajesh Yadav",
    reviewText: `"I booked a rental cab for a family function, and the experience was excellent. The vehicle was spacious, the driver was courteous, and the service was affordable."`,
  },
];

export default function CustomerReviewsSection() {
  return (
    <>
      {/* customer reviews section */}
      <div className="mt-[86px] flex flex-col items-center">
        <div className="container-xs flex flex-col items-center gap-8 px-14 lg:px-5 md:px-5">
          <Heading
            as="h2"
            className="text-[48px] font-semibold text-black-900_02 lg:text-[40px] md:text-[32px] sm:text-[26px]"
          >
            Customer Reviews
          </Heading>
          <div className="ml-[78px] flex gap-20 self-stretch md:ml-0 md:flex-col">
            <Suspense fallback={<div>Loading feed...</div>}>
              {userReviewsList.map((d, index) => (
                <UserReview {...d} key={"home" + index} />
              ))}
            </Suspense>
          </div>
        </div>
      </div>
    </>
  );
}
