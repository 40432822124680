import React from "react";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div
      style={{
        height: "100vh",
        padding: "20px",
        backgroundColor: "#232323",
        color: "white",
      }}
    >
      <h1 style={{ fontSize: "48px", marginBottom: "20px" }}>Homepage</h1>
      <p style={{ fontSize: "18px", marginBottom: "30px" }}>
        Use below links to navigate through all pages.
      </p>
      <ul style={{ listStyle: "none", padding: "0" }}>
        <li style={{ marginBottom: "10px" }}>
          <Link
            to="/contactus"
            style={{ color: "#87CEFA", textDecoration: "none" }}
          >
            Contact Us
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link
            to="/terms"
            style={{ color: "#87CEFA", textDecoration: "none" }}
          >
            Terms & Conditions
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link
            to="/privacy"
            style={{ color: "#87CEFA", textDecoration: "none" }}
          >
            Privacy Policy
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link
            to="/refund"
            style={{ color: "#87CEFA", textDecoration: "none" }}
          >
            Refund & Cancellation
          </Link>
        </li>
        <li style={{ marginBottom: "10px" }}>
          <Link
            to="/delete-account"
            style={{ color: "#87CEFA", textDecoration: "none" }}
          >
            Account Deletion
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Home;
