import { Text, Img } from "./..";
import React from "react";
import { Link } from "react-router-dom";

export default function Header({ ...props }) {
  return (
    <header
      {...props}
      className={`${props.className} flex justify-center items-center py-[26px] sm:py-4 bg-white-a700`}
    >
      <div className="container-xs flex items-center justify-between gap-5 px-[50px] lg:px-5 md:flex-col md:px-5">
        {/* Logo */}
        <Img
          onClick={() => window.location.href = "/"}
          src="images/img_header_logo.svg"
          alt="Headerlogo"
          className="h-[50px] w-[236px] object-contain"
        />

        {/* Navigation */}
        <div className="flex w-[30%] items-center justify-between gap-5 md:w-full sm:flex-col">
          <ul className="flex flex-wrap gap-16 lg:gap-5 md:gap-5 sm:gap-4 sm:flex-col sm:items-center">
            <li className="text-center">
              <Link to="/" className="lg:text-[17px]">
                <Text
                  as="p"
                  className="text-[20px] font-medium !text-black-900_03 sm:text-[18px]"
                >
                  Home
                </Text>
              </Link>
            </li>
            <li className="text-center">
              <Link to="/contactus" className="lg:text-[17px]">
                <Text
                  as="p"
                  className="text-[20px] font-medium !text-black-900_03 sm:text-[18px]"
                >
                  Contact Us
                </Text>
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
}
